import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import Button from "@material-ui/core/Button/Button";
import {makeSlug, toggleLocale, translate} from "../../../config/lang";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import AppBar from "@material-ui/core/AppBar/AppBar";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import SubTitle from "../../components/elements/SubTitle";
import CustomFields from "../advertisements/components/CustomFields";
import YourPlanDoesntSupportThis from "../Dashboard/components/YourPlanDoesntSupportThis";
import {hasAccess} from "../../../config/Security";
import RichEditor from "../../components/editor/RichEditor";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import {truncate} from "../../../config/values";
import PageviewRoundedIcon from "@material-ui/icons/PageviewRounded";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

class MakeEqualProduct extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            description:"",
            payment_method:'',
            meta_title:'',
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        logo: null,
        thumbnail: null,
        errors:{},
        categories:[],
        categoriesName:[],
        banners: [],
        videos: [],
        loading: true,
        SubmitLoading: false,
        confirmed: false,
        real_confirmed: false,
        slug: null,
        id:this.props.equal_id,
        editMode:false,
        owner_id:null,
        owner:null,
        updated_at:null,
        created_at:null,
        lat:"",
        lng:"",
        country:null,
        state:null,
        server_country:null,
        server_state:null,
        visits:0,
        today_visits:0,
        comments:0,
        lang: this.props.fake_locale,
        allowed_comment:0,
        item:this.props.item,
        modal:false,
        product:null,
        product_details:null,
        essential_details:null,
        packaging_delivery:null,
        supply_ability:null,
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            id: this.props.equal_id,
            loading:true
        })
        if(this.state.id){
            await this.setState({
                editMode:true
            });
            await this.loadData();
        }else{
            if(!!this.props.item.equal_categories){
                await this.setState({
                    categories: this.props.item.equal_categories.ids,
                    categoriesName: this.props.item.equal_categories.names,
                    parent_category: this.props.item.equal_categories.parent,
                });
            }
            await this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.equal_id){
            this.setState({
                id: this.props.equal_id,
                loading:true
            })
            if(!!this.state.id && !!this.props.equal_id){
                this.loadData();
            }
        }
    }
    handleLogoImage(logo){
        this.setState({
            logo
        })
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        this.setState({
            loading:true,
        })
        if(!this.state.id || !this.props.equal_id || (this.props.equal_id !== this.state.id)) {
            this.setState({
                editMode:false,
                loading:false,
                id:null
            })
        }else {
            let loginToken = localStorage.getItem('ACT');
            if(loginToken !== null){
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    }
                };
                await axios.get(`${defaultVariabls.url}/product/get/${this.state.id}`, config)
                    .then(response => {
                        this.setState({
                            fields: {
                                name: response.data.name,
                                description: !!response.data.description ? response.data.description : '',
                                payment_method: !!response.data.payment_method ? response.data.payment_method : '',
                                meta_title: !!response.data.meta_title ? response.data.meta_title : '',
                            },
                            content: {
                                HTML: response.data.html,
                                RAW: response.data.raw,
                            },
                            logo: response.data.logo,
                            confirmed: response.data.confirmed,
                            real_confirmed: response.data.confirmed,
                            slug: response.data.slug,
                            owner_id: response.data.user_id,
                            owner: response.data.owner,
                            updated_at: response.data.updated_at,
                            created_at: response.data.created_at,
                            visits:parseInt(response.data.visits_count),
                            today_visits:parseInt(response.data.today_visits_count),
                            lang:response.data.lang,
                            product_details:response.data.product_details,
                            essential_details:response.data.essential_details,
                            packaging_delivery:response.data.packaging_delivery,
                            supply_ability:response.data.supply_ability,
                        })
                        let categoriesTemp=[];
                        let categoriesNameTemp=[];
                        let galleryTemp=[];
                        let filesTemp=[];
                        let parent_category = null;
                        response.data.categories.map(item => {
                            if(!parent_category){
                                parent_category = item.id;
                            }
                            categoriesTemp.push(item.id)
                            categoriesNameTemp.push(item.name)
                            return true
                        })
                        response.data.gallery.map(item =>{
                            galleryTemp.push({
                                src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                                link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                                id: item.id,
                                name: item.name,
                                type: item.type,
                                preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                            })
                            return true
                        })
                        response.data.attachments.map(item =>{
                            filesTemp.push({
                                id: item.id,
                                name: item.name,
                                type: item.type,
                            })
                            return true
                        })

                        this.setState({
                            categories: categoriesTemp,
                            categoriesName: categoriesNameTemp,
                            gallery: galleryTemp,
                            files: filesTemp,
                            parent_category,
                            product:response.data,
                            equal:response.data.equal,
                            loading: false
                        })
                    }).catch(error => {
                        console.log(error.response)
                        /*this.props.history.push('/');
                        this.props.enqueueSnackbar(translate('pageNotFound'), {
                            variant: 'error',
                        });*/
                        this.setState({
                            loading:false
                        })
                    })
            }else {
                setStore({
                    isAuth : false,
                    user : null,
                });
                this.setState({
                    loading:false
                })
            }
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({
            fields,
        });
    }

    handleValidation(callback){
        let { fields,country,state,lat } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }


        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    async toggleModal(){
        await toggleLocale();
        await this.setState({
            modal: !this.state.modal
        })
    }
    handleRequest(){
        let { id,fields,editMode,confirmed,item,content,categories} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('payment_method', fields.payment_method);
        data.append('lang', this.props.fake_locale);
        data.append('description', fields.description);
        data.append('meta_title', fields.meta_title);
        data.append('id', id ? id : 0);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('equal_id', item.id);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('product_details', JSON.stringify(this.state.product_details));
        data.append('essential_details', JSON.stringify(this.state.essential_details));
        data.append('supply_ability', JSON.stringify(this.state.supply_ability));
        data.append('packaging_delivery', JSON.stringify(this.state.packaging_delivery));
        data.append('categories', JSON.stringify(categories));

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/product/make-equal`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('productEdited') : translate('productAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.loadData();
                this.toggleModal();
                this.props.reload();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('productEditingFailed') : translate('productAddingFailed'), {
                    variant: 'error',
                });

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }

    handleChangePhone(phone,name){
        let {fields,errors} = this.state;
        fields[name] = phone;
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
    }
    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })
    }
    async changeCategories(){
        await this.setState({
            categoriesName:[],
            categories:[],
            variables:[],
        })
    }
    handleCategoryHolder(categories){
        let {parent_category} = this.state;
        if(parent_category === null){
            this.setState({
                parent_category: categories[0]
            })
        }else if(parent_category !== categories[0]){
            this.setState({
                parent_category: categories[0]
            })
        }

        this.setState({categories})
    }
    render(){
        let {fields,currency,activePhoneNumbers,item,equal,product,modal,country,parent_category,loading,dislikes_count,likes_count,server_country,server_state,phoneNumberFilled,activeEmail,emailFilled, errors,gallery,slug,SubmitLoading,owner,thumbnail,editor_input_text,categoriesName,content,editMode,real_confirmed,confirmed,lat,lng} = this.state;
        const {user,list} = this.props;
        return (
            <>
                {
                    !!product ?
                        <Button
                            color={!!list ? 'default' : "secondary"}
                            variant={!!list ? 'text' : "outlined"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<EditRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}


                        >
                            {!!list ? truncate(product.name,8) : product.name }
                        </Button> :
                        <Button
                            color={!!list ? 'default' : "secondary"}
                            variant={!!list ? 'outlined' : "contained"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<AddRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}

                        >
                            {translate('addEqual')}
                        </Button>
                }
                <Dialog
                    open={this.state.modal}
                    maxWidth={"md"}
                    fullWidth
                    onClose={() => this.toggleModal()}
                >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {editMode ? translate('editProduct') :  translate('makeProduct')}
                            </Typography>
                            <IconButton color="inherit" onClick={() => this.toggleModal()} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent
                        className={this.classes.dialogRoot}
                    >
                        <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editProduct') :  translate('makeProduct')}>
                            {
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12} lg={11}>
                                        <Grid container spacing={2} justify="center">
                                            <Grid item xs={12}>
                                                {
                                                    !!real_confirmed ?
                                                        <>
                                                            <Button
                                                                component="a"
                                                                target="_blank"
                                                                size="small"
                                                                startIcon={<PageviewRoundedIcon/>}
                                                                href={makeSlug(`product/${slug}`,this.props.fake_locale)}
                                                                color="secondary"
                                                            >
                                                                {translate('viewProduct')}
                                                            </Button>
                                                        </> :
                                                        <>
                                                            <Button
                                                                component="a"
                                                                target="_blank"
                                                                size="small"
                                                                variant="contained"
                                                                startIcon={<PageviewRoundedIcon/>}
                                                                href={makeSlug(`product/${slug}`,this.props.fake_locale)}
                                                                color="secondary"
                                                            >
                                                                {translate('viewPreview')}
                                                            </Button>

                                                        </>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AppCard
                                                    title={editMode ? translate('editProduct') :  translate('makeProduct')}
                                                    action={
                                                        <BreadCrumbs singleLevel firstText={editMode ? translate('editProduct') :  translate('makeProduct')}/>
                                                    }>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{marginBottom:10}}>
                                                                <Grid item xs={12} md={6}>
                                                                    <TextField
                                                                        margin="normal"
                                                                        required
                                                                        fullWidth
                                                                        name="name"
                                                                        color="secondary"
                                                                        variant="outlined"
                                                                        label={translate('productName')}
                                                                        type="text"
                                                                        id="name"
                                                                        value={fields.name}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['name']}
                                                                        helperText={errors['name']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <TextField
                                                                        margin="normal"
                                                                        fullWidth
                                                                        name="payment_method"
                                                                        variant="outlined"
                                                                        color="secondary"
                                                                        label={translate('payment_method')}
                                                                        type="text"
                                                                        value={fields.payment_method}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['payment_method']}
                                                                        helperText={errors['payment_method']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        multiline
                                                                        fullWidth
                                                                        name="description"
                                                                        label={translate('seoDescription')}
                                                                        type="text"
                                                                        color="secondary"
                                                                        id="description"
                                                                        minRows="4"
                                                                        value={fields.description}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['description']}
                                                                        helperText={errors['description']}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <AppCard style={{height:'100%'}}>
                                                    <SubTitle align="left">
                                                        {translate('product_details')}
                                                    </SubTitle>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <>
                                                                <CustomFields
                                                                    equal
                                                                    type={"product"}
                                                                    position_type="product_details"
                                                                    input={this.state.product_details}
                                                                    category_id={this.state.parent_category}
                                                                    handleCustomFields={(product_details) => this.setState({product_details})}
                                                                />
                                                            </>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            {
                                                !!this.state.parent_category  &&
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <AppCard style={{height:'100%'}}>
                                                            <SubTitle align="left">
                                                                {translate('essential_details')}
                                                            </SubTitle>
                                                            {
                                                                this.state.loading ? <AppCardLoaderFields/> :
                                                                    <>
                                                                        <CustomFields
                                                                            equal
                                                                            type={"product"}
                                                                            position_type="essential_details"
                                                                            input={this.state.essential_details}
                                                                            category_id={this.state.parent_category}
                                                                            handleCustomFields={(essential_details) => this.setState({essential_details})}
                                                                        />
                                                                    </>
                                                            }
                                                        </AppCard>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <AppCard style={{height:'100%'}}>
                                                            <SubTitle align="left">
                                                                {translate('supply_ability')}
                                                            </SubTitle>
                                                            {
                                                                this.state.loading ? <AppCardLoaderFields/> :
                                                                    <>
                                                                        <CustomFields
                                                                            equal
                                                                            type={"product"}
                                                                            position_type="supply_ability"
                                                                            input={this.state.supply_ability}
                                                                            category_id={this.state.parent_category}
                                                                            handleCustomFields={(supply_ability) => this.setState({supply_ability})}
                                                                        />
                                                                    </>
                                                            }
                                                        </AppCard>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <AppCard style={{height:'100%'}}>
                                                            <SubTitle align="left">
                                                                {translate('packaging_delivery')}
                                                            </SubTitle>
                                                            {
                                                                this.state.loading ? <AppCardLoaderFields/> :
                                                                    <>
                                                                        <CustomFields
                                                                            equal
                                                                            type={"product"}
                                                                            position_type="packaging_delivery"
                                                                            input={this.state.packaging_delivery}
                                                                            category_id={this.state.parent_category}
                                                                            handleCustomFields={(packaging_delivery) => this.setState({packaging_delivery})}
                                                                        />
                                                                    </>
                                                            }
                                                        </AppCard>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <AppCard>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <SubTitle align="left">{translate('productDescription')}</SubTitle>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <YourPlanDoesntSupportThis visible={!hasAccess('has_html')}/>

                                                                    <RichEditor
                                                                        inputText={editor_input_text}
                                                                        ref={this.EditorChild}
                                                                        Output={this.handleOutPut.bind(this)}
                                                                        input={content.RAW}
                                                                        minHeight={300}
                                                                        readOnly={!hasAccess('has_html')}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            {
                                                (!!user.role.permission.manageShops) &&
                                                <Grid item xs={12}>
                                                    <AppCard style={{height:'100%'}}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    margin="normal"
                                                                    name="meta_title"
                                                                    variant="outlined"
                                                                    label={translate('meta_title')}
                                                                    value={fields.meta_title}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['meta_title']}
                                                                    helperText={errors['meta_title']}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AppCard>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    style={{minWidth:200}}
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                >
                                                    {editMode ? translate('editProduct') :  translate('makeProduct')}
                                                </LoadingButton>
                                            </Grid>
                                            {
                                                (!!editMode && !this.state.loading) &&
                                                <Grid item xs={12}>
                                                    <Alert severity="warning" variant="standard"
                                                           style={{marginBottom: 10}}>
                                                        <Typography
                                                            style={{direction: this.props.fake_locale === "en" ? "ltr" : "rtl",}}>
                                                            {translate('ifYouWantToSeeMoreInfoClick')}
                                                            {" "}
                                                            <Button
                                                                size="small"
                                                                color="secondary"
                                                                component='a'
                                                                href={`${defaultVariabls.React}/edit/product?lang=${this.props.fake_locale}`}
                                                            >
                                                                {translate('clickHere')}
                                                            </Button>
                                                        </Typography>
                                                        <Typography style={{fontSize: 11}}>
                                                            {translate('attentionText')}
                                                        </Typography>
                                                    </Alert>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>

                            }
                        </AppAnimate>
                    </DialogContent>

                </Dialog>
            </>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeEqualProduct))));