import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Link, withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Typography from "@material-ui/core/Typography/Typography";
import ChangePassword from "./components/ForgetPassword/ChangePassword";
import {defaultVariabls} from "../../../config";
import axios from "axios";
import {connect, setStore} from "trim-redux";
import HeaderTags from "../../components/elements/HeaderTags";
import Box from "@material-ui/core/Box/Box";
import AuthLayout from "../../components/layouts/AuthLayout";
import {translate} from "../../../config/lang";
import ChooseEmail from "./components/ForgetPassword/chooseEmail";
import VerifyUserEmail from "./components/ForgetPassword/VerifyUserEmail";

class ForgetPassword extends React.Component {
    state={
        level:1,
        user:null
    }
    setNextLevel(){
        this.setState({
            level: this.state.level+1
        })
    }

    async setUser(user){
        await this.setState({
            user
        })
    }
    makeLogin(data){
        localStorage.setItem('ACT',data.accessToken);
        this.checkLogin();
    }
    async checkLogin(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                        umc : response.data.umc,
                    });
                    this.setState({
                        loading:false
                    })
                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                    loading:false
                });
                this.setState({
                    loading:false
                })
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
        if (this.props.isAuth) {
            let code = this.props.user.login_token;
            let {iframe} = this.props;
            let token = localStorage.getItem('ACT');
            let lang = localStorage.getItem('locale');
            let mode = localStorage.getItem('mode');
            if (mode === null) {
                mode = "light";
            }
            if (iframe) {
                await window.parent.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            } else {
                await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }
        }
    }
    render() {
        const {classes,iframe} = this.props;

        const {level,user} = this.state;
        return (
            <AuthLayout iframe={iframe}>
                <HeaderTags title={translate('forgetPassword')}/>

                <Grid item md={10} lg={8}>
                    <Box mb={{xs: 3, xl: 8}}  fontSize={20}>
                        <strong className={classes.auth_title}>
                            {translate('forgetPassword')}
                        </strong>
                    </Box>
                        {
                            level === 1 ? <ChooseEmail iframe={iframe} setUser={this.setUser.bind(this)} setNextLevel={this.setNextLevel.bind(this)}/> : null
                        }
                        {
                            level === 2 ? <VerifyUserEmail user={user}  setNextLevel={this.setNextLevel.bind(this)}/> : null
                        }
                        {
                            level === 3 ? <ChangePassword user={user}  makeLogin={this.makeLogin.bind(this)}/> : null
                        }
                        <Link to={iframe ? "/iframe/login" : "/login"}  variant="body2">
                        <Typography align="left" className="withCursor" variant="body2" color="secondary"> {translate('backToLogin')}</Typography>
                        </Link>
            </Grid>
            </AuthLayout>

        );

    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ForgetPassword))));