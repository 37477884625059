import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Button from "@material-ui/core/Button/Button";
import {makeSlug, toggleLocale, translate} from "../../../config/lang";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import AppBar from "@material-ui/core/AppBar/AppBar";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import SubTitle from "../../components/elements/SubTitle";
import CustomFields from "../advertisements/components/CustomFields";
import LinearCategoryHolder from "../../components/fields/LinearCategoryHolder";
import CheckUserCanAdRFQ from "./components/CheckUserCanAdRFQ";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import PageviewRoundedIcon from "@material-ui/icons/PageviewRounded";
import {truncate} from "../../../config/values";

class MakeEqualRFQ extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            description:"",
            payment_terms:'',
            shipping_terms:'',
            meta_title:'',
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        logo: null,
        thumbnail: null,
        errors:{},
        categories:[],
        categoriesName:[],
        banners: [],
        videos: [],
        loading: true,
        SubmitLoading: false,
        confirmed: false,
        real_confirmed: false,
        slug: null,
        id:this.props.equal_id,
        editMode:false,
        owner_id:null,
        owner:null,
        updated_at:null,
        created_at:null,
        lat:"",
        lng:"",
        country:null,
        state:null,
        server_country:null,
        server_state:null,
        visits:0,
        today_visits:0,
        comments:0,
        lang: this.props.locale,
        allowed_comment:0,
        item:this.props.item,
        modal:false,
        rfq:null,
        custom_fields:null,
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            id: this.props.equal_id,
            loading:true
        })
        if(this.state.id){
            await this.setState({
                editMode:true
            });
            await this.loadData();
        }else{
            if(!!this.props.item.equal_categories){
                await this.setState({
                    categories: this.props.item.equal_categories.ids,
                    categoriesName: this.props.item.equal_categories.names,
                    parent_category: this.props.item.equal_categories.parent,
                });
            }
            await this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.equal_id){
            this.setState({
                id: this.props.equal_id,
                loading:true
            })
            if(!!this.state.id && !!this.props.equal_id){
                this.loadData();
            }
        }
    }
    handleLogoImage(logo){
        this.setState({
            logo
        })
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        this.setState({
            loading:true,
        })
        if(!this.state.id || !this.props.equal_id || (this.props.equal_id !== this.state.id)) {
            this.setState({
                editMode:false,
                loading:false,
                id:null
            })
        }else {
            let loginToken = localStorage.getItem('ACT');
            if (loginToken !== null) {
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    }
                };
                await axios.get(`${defaultVariabls.url}/rfq/get/${this.state.id}`, config)
                    .then(response => {
                        this.setState({
                            fields: {
                                name: response.data.name,
                                description: !!response.data.description ? response.data.description : '',
                                payment_terms: !!response.data.payment_terms ? response.data.payment_terms : '',
                                shipping_terms: !!response.data.shipping_terms ? response.data.shipping_terms : '',
                                meta_title: !!response.data.meta_title ? response.data.meta_title : '',
                            },
                            content: {
                                HTML: response.data.html,
                                RAW: response.data.raw,
                            },
                            logo: response.data.logo,
                            confirmed: response.data.confirmed,
                            real_confirmed: response.data.confirmed,
                            slug: response.data.slug,
                            owner_id: response.data.user_id,
                            owner: response.data.owner,
                            updated_at: response.data.updated_at,
                            created_at: response.data.created_at,
                            visits: parseInt(response.data.visits_count),
                            today_visits: parseInt(response.data.today_visits_count),
                            lang: response.data.lang,
                            custom_fields: response.data.custom_fields,
                        })
                        let categoriesTemp = [];
                        let categoriesNameTemp = [];
                        let filesTemp = [];
                        let parent_category = null;
                        response.data.categories.map(item => {
                            if (!parent_category) {
                                parent_category = item.id;
                            }
                            categoriesTemp.push(item.id)
                            categoriesNameTemp.push(item.name)
                            return true
                        })
                        response.data.attachments.map(item => {
                            filesTemp.push({
                                id: item.id,
                                name: item.name,
                                type: item.type,
                            })
                            return true
                        })

                        this.setState({
                            categories: categoriesTemp,
                            categoriesName: categoriesNameTemp,
                            files: filesTemp,
                            parent_category,
                            rfq: response.data,
                            equal: response.data.equal,
                            loading: false
                        })
                    }).catch(error => {
                        console.log(error.response)
                        /*this.props.history.push('/');
                        this.props.enqueueSnackbar(translate('pageNotFound'), {
                            variant: 'error',
                        });*/
                        this.setState({
                            loading: false
                        })
                    })
            } else {
                setStore({
                    isAuth: false,
                    user: null,
                });
                this.setState({
                    loading: false
                })
            }
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({
            fields,
        });
    }

    handleValidation(callback){
        let { fields,country,state,lat } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }
        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 1000) {
                formIsValid = false;
                errors['description'] = translate('maxLength1000');
            }
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    async toggleModal(){
        await toggleLocale();
        await this.setState({
            modal: !this.state.modal
        })
    }
    handleRequest(){
        let { id,fields,editMode,confirmed,item,content,categories} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('payment_terms', fields.payment_terms);
        data.append('shipping_terms', fields.shipping_terms);
        data.append('lang', this.props.fake_locale);
        data.append('description', fields.description);
        data.append('meta_title', fields.meta_title);
        data.append('id', id ? id : 0);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('equal_id', item.id);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('custom_fields', JSON.stringify(this.state.custom_fields));
        data.append('categories', JSON.stringify(categories));

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/rfq/make-equal`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('rfqEdited') : translate('rfqAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.loadData();
                this.toggleModal();
                this.props.reload();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('rfqEditingFailed') : translate('rfqAddingFailed'), {
                    variant: 'error',
                });

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }

    handleChangePhone(phone,name){
        let {fields,errors} = this.state;
        fields[name] = phone;
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
    }
    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })
    }
    async changeCategories(){
        await this.setState({
            categoriesName:[],
            categories:[],
            variables:[],
        })
    }
    handleCategoryHolder(categories){
        let {parent_category} = this.state;
        if(parent_category === null){
            this.setState({
                parent_category: categories[0]
            })
        }else if(parent_category !== categories[0]){
            this.setState({
                parent_category: categories[0]
            })
        }

        this.setState({categories})
    }
    render(){
        let {fields,currency,activePhoneNumbers,item,equal,rfq,modal,country,parent_category,loading,dislikes_count,likes_count,server_country,server_state,phoneNumberFilled,activeEmail,emailFilled, errors,gallery,slug,SubmitLoading,owner,thumbnail,editor_input_text,categoriesName,content,editMode,real_confirmed,confirmed,lat,lng} = this.state;
        const {user,list} = this.props;
        return (
            <>
                {
                    !!rfq ?
                        <Button
                            color={!!list ? 'default' : "secondary"}
                            variant={!!list ? 'text' : "outlined"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<EditRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}
                        >
                            {!!list ? truncate(rfq.name,8) : rfq.name }
                        </Button> :
                        <Button
                            color={!!list ? 'default' : "secondary"}
                            variant={!!list ? 'outlined' : "contained"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<AddRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}
                        >
                            {translate('addEqual')}
                        </Button>
                }
                <Dialog
                    open={this.state.modal}
                    maxWidth={"md"}
                    fullWidth
                    onClose={() => this.toggleModal()}
                >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {editMode ? translate('editRFQ') :  translate('makeRFQ')}
                            </Typography>
                            <IconButton color="inherit" onClick={() => this.toggleModal()} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent
                        className={this.classes.dialogRoot}
                    >
                        <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editRFQ') :  translate('makeRFQ')}>
                            {
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12} lg={11}>
                                        <Grid container spacing={2} justifyContent="center">
                                            {
                                                (user.role.type !== "admin" && !loading) &&
                                                <CheckUserCanAdRFQ edit={!!editMode}/>
                                            }
                                            {
                                                (!loading && !!editMode) &&
                                                <Grid item xs={12}>
                                                    {
                                                        !!real_confirmed ?
                                                            <>
                                                                <Button
                                                                    component="a"
                                                                    target="_blank"
                                                                    size="small"
                                                                    startIcon={<PageviewRoundedIcon/>}
                                                                    href={makeSlug(`rfq/${slug}`,this.props.fake_locale)}
                                                                    color="secondary"
                                                                >
                                                                    {translate('viewProduct')}
                                                                </Button>
                                                            </> :
                                                            <>
                                                                <Button
                                                                    component="a"
                                                                    target="_blank"
                                                                    size="small"
                                                                    variant="contained"
                                                                    startIcon={<PageviewRoundedIcon/>}
                                                                    href={makeSlug(`rfq/${slug}`,this.props.fake_locale)}
                                                                    color="secondary"
                                                                >
                                                                    {translate('viewPreview')}
                                                                </Button>

                                                            </>
                                                    }
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <AppCard
                                                    title={editMode ? translate('editRFQ') :  translate('makeRFQ')}
                                                    action={
                                                        <BreadCrumbs singleLevel firstText={editMode ? translate('editRFQ') :  translate('makeRFQ')}/>
                                                    }>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{marginBottom:10}}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        margin="normal"
                                                                        required
                                                                        color="secondary"
                                                                        fullWidth
                                                                        name="name"
                                                                        variant="outlined"
                                                                        label={translate('rfqName')}
                                                                        placeholder={translate('rfqNameDesc')}
                                                                        type="text"
                                                                        id="name"
                                                                        value={fields.name}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['name']}
                                                                        helperText={errors['name']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <TextField
                                                                        margin="normal"
                                                                        fullWidth
                                                                        name="payment_terms"
                                                                        variant="outlined"
                                                                        label={translate('payment_terms')}
                                                                        type="text"
                                                                        color="secondary"
                                                                        value={fields.payment_terms}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['payment_terms']}
                                                                        helperText={errors['payment_terms']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <TextField
                                                                        margin="normal"
                                                                        fullWidth
                                                                        name="shipping_terms"
                                                                        variant="outlined"
                                                                        label={translate('shipping_terms')}
                                                                        type="text"
                                                                        color="secondary"
                                                                        value={fields.shipping_terms}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['shipping_terms']}
                                                                        helperText={errors['shipping_terms']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        multiline
                                                                        fullWidth
                                                                        color="secondary"
                                                                        name="description"
                                                                        label={translate('seoDescription')}
                                                                        type="text"
                                                                        id="description"
                                                                        minRows="4"
                                                                        value={fields.description}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['description']}
                                                                        helperText={errors['description']}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <FieldCounterWithoutBar length={fields.description.length} max={1000}/>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AppCard style={{height:'100%'}}>
                                                    <SubTitle align="left">
                                                        {translate('rfq_custom_fields')}
                                                    </SubTitle>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <>
                                                                <CustomFields
                                                                    equal
                                                                    type={"rfq"}
                                                                    position_type="custom_fields"
                                                                    input={this.state.custom_fields}
                                                                    category_id={this.state.parent_category}
                                                                    handleCustomFields={(custom_fields) => this.setState({custom_fields})}
                                                                />
                                                            </>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            {
                                                (!!user.role.permission.manageShops) &&
                                                <Grid item xs={12}>
                                                    <AppCard style={{height:'100%'}}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    margin="normal"
                                                                    name="meta_title"
                                                                    variant="outlined"
                                                                    label={translate('meta_title')}
                                                                    value={fields.meta_title}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['meta_title']}
                                                                    helperText={errors['meta_title']}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AppCard>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    style={{minWidth:200}}
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                >
                                                    {editMode ? translate('editRFQ') :  translate('makeRFQ')}
                                                </LoadingButton>
                                            </Grid>
                                            {
                                                (!!editMode && !this.state.loading) &&
                                                <Grid item xs={12}>
                                                    <Alert severity="warning" variant="standard"
                                                           style={{marginBottom: 10}}>
                                                        <Typography
                                                            style={{direction: this.props.fake_locale === "en" ? "ltr" : "rtl",}}>
                                                            {translate('ifYouWantToSeeMoreInfoClick')}
                                                            {" "}
                                                            <Button
                                                                size="small"
                                                                color="secondary"
                                                                component='a'
                                                                href={`${defaultVariabls.React}/edit/rfq?lang=${this.props.fake_locale}`}
                                                            >
                                                                {translate('clickHere')}
                                                            </Button>
                                                        </Typography>
                                                        <Typography style={{fontSize: 11}}>
                                                            {translate('attentionText')}
                                                        </Typography>
                                                    </Alert>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </AppAnimate>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeEqualRFQ))));