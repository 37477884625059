export const EN = {
    ar: "AR",
    en: "EN",
    login:"Login",
    existEmail: "This email is already registered.",
    existMobile: "This mobile number is already registered.",
    emptyEmail: "Enter your email address.",
    emptyMobile: "Enter your mobile number.",
    wrongEmail: "Email format is wrong.",
    wrongMobile: "The mobile number format is wrong.",
    emptyEmailOrMobile:"Enter your Email or mobile number",
    loginError: "Your Email or Password  is wrong.",
    emailOrMobile: "Email or mobile number",
    first_name: "First Name",
    last_name: "Last Name",
    company_name: "Company Name",
    phone: "Phone",
    passwordConfirm: "Repeat password",
    passwordStrength: "At least 6 characters, including numbers and letters",
    passwordChanged:"Password changed successfully.",
    currentPasswordError: "The current password is not correct.",
    currentPassword:"Current password",
    register: "Register",
    doRegister: "create a new account",
    dontHaveAccount: "Don't have an account?",
    haveAccount: "Already have a B2b Mineral account?",
    forgetPass: "Forgot Password",
    loginWithToken:"Login with SMS",
    password: "Password",
    backToLogin: "Back to login page",
    doLogin: "Sign in",
    rules: "terms and conditions",
    withRegistering: "By registering on this site, I accept its",
    privacyPolicy: "Privacy Rules",
    privacyPolicyConfirmation: ".",
    mobile: "Mobile",
    email: "Email",
    sms: "short message",
    verifyEmail: "Verify Email",
    registerSuccess: "User registered successfully.",
    registerError: "Error creating user",
    youLoggedOff:"You are logged out",
    mobileVerified:"Your mobile number has been verified.",
    wrongToken:"The verification code is wrong. ",
    mobileVerify: "Mobile Verification",
    verifyMobile: "verify mobile number",
    sendTokenText1:"Your one-time token sent to",
    sendTokenText2:" .",
    sendTokenTextResend:"The one-time password has been resent to you.",
    sendTokenText3:"Remaining time to resend SMS",
    logOut: "Logout",
    confirm: "Confirm",
    token:"verification code",
    forgetPassword: "Forgot Password",
    invalidMobileNumber: "This Mobile number does not exist.",
    recoverPassword:"recover password",
    recoverPasswordText:"Enter your mobile number to recover your password.",
    sendAgain: "Send again",
    changePassword: "Change password",
    managePassword: "Manage Password",
    newPassword:"New password",
    newPasswordConfirm:"Repeat new password",
    loginWithUserPass:"Login with Email",
    addYourNumberFirst:"Enter your mobile number to enter the site.",
    errorHappens:"An error occurred while loading the Website!",
    allChangesSaved:"All changes saved.",
    allChangesSavingFailed:"Saving changes failed!",
    connectionError:'Your internet is down.',
    pleaseCheckTheBox:"Confirm Terms and Conditions Please!",
    emptyField:'The field is empty!',
    makingTokenError: "There is a problem creating the verification code.",
    editMobile: "Edit mobile number",
    editMobileDesc: "If you want to change your mobile number (",
    editMobileDesc2: "), enter it.",
    save: "Save",
    dataAdded: "Your data has been created.",
    dataAddingFailed: "There was a problem creating data.",
    emptyPassword: "Enter your password",
    unreadNotifications: "Unread notifications",
    readNotifications: "Read Notifications",
    noNotifications: "There is no Notification!",
    nothingFound: "The page does not exist!",
    markAsRead: 'Mark as Read',
    copyright: "All rights reserved © ",
    dashboard:"Dashboard",
    account: "Manage Account",
    fileManager: "File Manager",
    myDetails: "My Account",
    myAccount: "My Account",
    doYouWanaExit: "Do you want to exit?",
    readMore: "Read more",
    areYouSure: "Are you sure?",
    yes: "Yes",
    no: "No",
    userPanel: "Panel",
    userMobileIsConfirmed: "The user's mobile number is confirmed.",
    userMobileIsNotConfirmed: "The user's mobile number is not confirmed.",
    doYouWanaConfirm: "Do you confirm the user's mobile number?",
    loginToUserAccount: "login to this user's account",
    userShouldConfirmMobileFirst: "The user's mobile number must be confirmed first!",
    youAreInSomeOneAccount: "You are in someone else's account!",
    backToMyAccount: "back",
    setDetails: "Save Changes",
    detailsSet:"Information was registered.",
    detailsSetFailed:"There was a problem registering information.",
    detailsGetFailed:"There was a problem getting information.",
    someFieldsHasErrorPleaseCheckThem: "One or more fields have errors, please check them",
    someFieldsHasError: "One or more fields have errors, please check",
    maxLength70: "The length of this field should not exceed 70 characters.",
    maxLength150: "The length of this field should not exceed 150 characters.",
    maxLength1500: "The length of this field should not exceed 1500 characters.",
    maxLength255: "The length of this field should not exceed 255 characters.",
    maxLength500: "The length of this field should not exceed 500 characters.",
    maxLength1000: "The length of this field should not exceed 1000 characters.",
    chooseMedia: "Choose File",
    submitErrorFile:"There is a problem with the submitted file.",
    imageSizeWidth700: "The proper width of the image is 700px.",
    thumbnailSize: "Minimum image width: 600px",
    userAvatarSet:"Your profile picture has been updated.",
    profileImage: "Profile Image",
    userAvatarSetFailed:"There was a problem in changing the profile picture.",
    loadingThumbnailFailed: "There was a problem loading the image",
    ChooseImageLink:" To copy the link, choose the desired size.",
    deleteFile:"File deleted successfully",
    deleteFileFailed: "There was a problem deleting the file.",
    deleteSelectedFiles:"Files deleted successfully",
    alt: "alternative text (alt)",
    chooseImage: "Choose image",
    emptyImage: "Choose an image!",
    descriptionPlaceHolder: "Write the relevant description",
    words:"Word",
    characters:"Character",
    uploadImage: "Upload Media",
    ThumbnailTitle: "Main Image",
    loading: "Loading...",
    makePassword: "Make password",
    addImage: "Add image",
    imageName: "File name",
    ownerMedia:"Publisher",
    insertMedia:"Insert file",
    insert: "Insert",
    linkCopied:"The link was copied.",
    uploadSuccess: "Upload was successful",
    editFile:"File edited successfully",
    editFileFailed: "Editing the file encountered a problem.",
    tableAdded:"Table was created",
    tableAddingFailed:"Adding table failed.",
    deleteTable:"Table deleted successfully",
    deleteTableFailed:"There was a problem deleting the table.",
    tableEdited:"The table was edited.",
    tableEditingFailed:"Table editing failed.",
    selfFiles: "My files",
    adminsFiles: "Site Admins files",
    everyBodyFiles: "All users files",
    uploading: "Uploading...",
    uploadDone: "File uploaded.",
    allTypes: "All formats",
    image: "Image",
    video: "Video",
    document: "Document",
    chooseVideo:"Choose video",
    multiImport:"select media",
    filesTable: "saved files",
    lastUpdate: "Last Update",
    search: "Search",
    from: "from",
    labelRowsSelect: "Rows",
    labelRowsPerPage: "Number of rows per page",
    firstAriaLabel: "First page",
    firstTooltip: "First page",
    previousAriaLabel: "Previous page",
    previousTooltip: "Previous page",
    nextAriaLabel: "Next page",
    nextTooltip: "Next page",
    lastAriaLabel: "Last page",
    lastTooltip: "Last page",
    exportTitle: "Get output file",
    exportAriaLabel: "Get output file",
    exportCSVName: "Get CSV output",
    emptyDataSourceMessage: 'There is no content to display!',
    addTooltip: 'Add',
    deleteTooltip: 'Delete',
    editTooltip: 'Quick Edit',
    filterTooltip: 'Filter',
    deleteText: 'Are you sure to delete this row?',
    cancelTooltip: 'Cancel',
    saveTooltip: 'Save',
    rowsSelected: 'selected row',
    uploadedDate: "upload time",
    linkPlaceholder:"Enter a URL",
    number: "Number",
    icon: "Icon",
    link: "Link",
    filters: "Filters",
    chooseColor: "Choose color",
    chooseFormat: "Choose format",
    editAdvertisement: "Edit advertisement",
    makeAdvertisement:"Create advertisement",
    advertisementName:"Ad Title",
    advertisements: "Advertisements",
    advertisement: "Advertisement",
    advertisementsList:"List of advertisements",
    viewAdvertisement:"View Ad",
    updateTime: "Update",
    created_at: "Created date",
    updated_at: "Update date",
    gallery: 'Image gallery',
    requestToDeleteAd: "Request to delete Ad",
    removeAd: "Remove ad",
    requestedToDelete: "The removal request has been sent!",
    pageNotFound: "This page is not available!",
    contentAdded: "Your post has been created.",
    contentAddingFailed: "There was a problem creating the post.",
    contentEdited: "Your post has been edited.",
    contentEditingFailed: "There was a problem editing the post.",
    deleteContent: "Post deleted.",
    deleteContentFailed: "There was a problem deleting the post.",
    firstConfirmShop: "Confirm this user's shop first!",
    confirmationStatusDescription: "Information confirmation status",
    confirmationStatusFilter: "Status",
    userConfirmationStatus: "Confirmation status of user information",
    confirmed: "Confirmed",
    unConfirmed: "Pending",
    all: "All",
    yourAdvertisementIsActive: "Congratulations! Your ad is verified and active.",
    yourAdvertisementIsInchecking: "Your advertisement is being checked!",
    price:"Price",
    changeCategories: "Change categories",
    advertisementDescription: "Complete advertisement explanation",
    seoDescription: "Brief explanation",
    welcomeToPanel: "Welcome to B2B Mineral user panel!",
    agreement: "Terms & conditions",
    pleaseConfirmTerms: "Please read and accept the Terms & conditions.",
    rulesConfirmation: " and approve it.",
    with: "I agree with the ",
    manageAdvertisements: "Manage Advertisements",
    myAdvertisements: "My Advertisements",
    thumbnail: "Image",
    creator: "Publisher",
    edit: "Edit",
    view: "View",
    activity: 'Activity',
    details: 'Details',
    deleteAdvertisement: "Advertisement deleted!",
    deleteAdvertisementFailed: "There was a problem deleting the advertisement!",
    chooseCategory: "Choose Category",
    maxGallerySizeIs9: "The maximum number of gallery images is 9!",
    manageComments: "Manage Comments",
    manageCategories: "Manage Categories",
    usersManagement: "User Management",
    allUsers: "All Users",
    usersActivity: "Users Activity",
    managePages: "Manage Pages",
    manageContact: "Contact Form",
    siteSettings: "Site Settings",
    siteInformation: "Main Settings",
    manageSlideShows: "Manage slideshow",
    manageBanners: "Manage banners",
    editSlideShow: "Edit slideshow",
    custom_css: "Additional CSS code",
    custom_js: "Additional Java Script Code",
    custom_head: "Added code in <head> Website",
    custom_body: "Additional code in the <body> of the Website",
    custom_code: "additional code",
    siteContactInformation: "Contact information",
    moreInfo: "More information",
    makeCategory: "Create Category",
    editCategory: "Edit Category",
    categoryName: "category name",
    categoryParentName: "Parent Category",
    categories: "Categories",
    pleaseChooseCategoriesFirst: "Please choose a category first!",
    category: "Category",
    chooseSubCategory: "Choose subcategory",
    chooseMainCategory: "Choose the main category",
    removeImage: 'Remove image',
    viewCategory: 'View Category',
    username: "Username",
    userName: "Name",
    confirmationStatus: "Status",
    registerDate: "Registration Date",
    changeRole: "Change access level",
    role: "Access level",
    allRoles: "All access levels",
    exportUsersList: "Download list of users",
    addUser: "Add User",
    editUser: "Edit User",
    editDetails: "Edit Profile",
    dataEdited: "Your information has been edited.",
    dataEditingFailed: "There was a problem editing data.",
    deleteData: "Data deleted.",
    deleteDataFailed: "There was a problem deleting this data.",
    admin: "Admin",
    user: "User",
    supplier: "Seller",
    supportUser: "Support",
    product_manager: "Product Manager",
    support: "Support",
    adminType: "Admins",
    normalType: "Other users",
    usersLog: "Users Activities",
    saveCode: "Save Codes",
    writeYourCodehere: "Write your codes here",
    customCodeTitle: "Add the codes you need!",
    customCodeText: "In this section, you can add the codes you need in different sections of your website and customize them!",
    main_phone: "Website Phone number",
    main_email: "Website Email",
    main_mobile: "Website Mobile number",
    main_address: "Website Address",
    facebook: "Facebook link",
    linkedin: "LinkedIn Link",
    pinterest: "Pinterest link",
    instagram: "Instagram link",
    twitter: "Twitter (X) Link",
    whatsapp: "Whatsapp Number",
    telegram: "Telegram link",
    headerAndFooter: "مدیریت هدر و فوتر",
    short_description: "short description of the website",
    footer_text: "Footer description text",
    footer_certificates: "Footer Symbols Code",
    map_script: "Contact page map code",
    bg_image: "Background image",
    gradientBg: "Gradient background",
    solidBg: "Solid color background",
    imageBg: "Background with image",
    gradientBgFirstColor: "Gradient first color",
    gradientBgSecondColor: "Gradient second color",
    bg_type: "Background image type",
    slideShowImages: "Image elements",
    SlideShowContent: "Slideshow content",
    nextStep: "Next part",
    mainImageSlide: "Main image slide",
    contactInformation: "Website Contact information",
    footerCertificationTitle: "Certificates Code",
    footerCertificationText: "In this section, you can put the codes received from different organizations such as trust symbol, digital media, etc.",
    mapScriptTitle: "Map Script",
    mapScriptText: "In this section, you can put the codes received from different map platforms, such as Google Map, to be displayed on the Contact page.",
    saveChanges: "Save Changes",
    adminDashboard: "Admin Dashboard",
    userDashboard: "User Dashboard",
    currencies: "Currencies",
    addContactInformation: "Add Contact Information",
    phoneNumber: "Phone number",
    addPhoneNumber: "Add number",
    addEmail: "Add Email",
    relatedLinks: "Related Links",
    websiteLink: "Website URL",
    facebookLink: "Facebook URL",
    whatsappLink: "WhatsApp Link",
    telegramLink: "Telegram URL",
    instagramLink: "Instagram URL",
    twitterLink: "Twitter (X) URL",
    websitePlaceHolder: "https://yourwebsite.com",
    facebookPlaceHolder: "https://facebook.com/your_page",
    whatsappPlaceHolder: "+9712345678",
    telegramPlaceHolder: "https://t.me/your_number",
    instagramPlaceHolder: "https://instagram.com/your_page",
    twitterPlaceHolder: "https://twitter.com/your_page",
    phoneIsWrong: "Phone number is wrong!",
    youtube: "Youtube",
    addFromYoutube: "Add video from Youtube",
    addYoutubeLinkHere: "Please insert a video URL from Youtube in the field below!",
    addVideo: "Add Video",
    youtubeLink: "Youtube video URL",
    back: "Back",
    wrongURlForYoutube: "URL is Wrong!",
    toggleDark: "Dark mode",
    toggleLight: "Light mode",
    addMainDetails: "Main details",
    addImages: "Add Images",
    primaryAdImage: "Ad Primary image",
    savedFiles: "Uploaded files",
    fileSize20mb: "File size must be less than 20MB!",
    fileSize100mb: "File size must be less than 100MB!",
    addPreviewToVideo: "Add preview to video",
    doYouWantToAddThumbnail: "Do you want to add a thumbnail to this video?",
    addPreviewImage: "select preview image",
    addVideoWithoutPreview: "Add video without preview",
    adsGallery: "Ad Gallery",
    removeItem: "Remove Item",
    addItem: "Add Item",
    address: "Address",
    addressAndLocation: "Address and Location",
    country: "Country",
    state: "State",
    yourLocation: "Your location",
    searchInMap: "Search in Map",
    adAdded: "Your ad has been created.",
    adAddingFailed: "There was a problem creating the ad.",
    adEdited: "Your ad has been edited.",
    adEditingFailed: "There was a problem editing the ad.",
    commentEdited: "Comment edited",
    commentAdded:"Comment was created",
    commentAddingFailed:"There was a problem adding a comment.",
    commentEditingFailed: "There was a problem editing the comment.",
    commentRemoved: "Comment removed successfully",
    rating: "Rating",
    comment: "Comment",
    comments: "Comments",
    editComment: "Edit Comment",
    addComment: "Add Comment",
    chooseAdForComment: "choose ad for comment",
    noAds: "No ads found!",
    commentForReplayDescription: "Choose here if you want to reply to the comment",
    parentCommentDesc : "You are replying to the following comment:",
    site_logo: "Site logo",
    favicon: "Site icon (FavIcon)",
    square_logo: "Square logo",
    page_header: "Header of internal pages",
    pagination_count: "Items per page",
    page_count: "Number of pages to load with Ajax",
    app_name: "Website name",
    youtubeChanel: "Youtube chanel link",
    youtubePlaceHolder: "https://www.youtube.com/channel/your_chanel",
    viewPreview: "Preview",
    userLikes: "Users like",
    adInfo: "Ad information",
    viewAd: "View ad",
    pleaseChooseYourLocation: "Please choose your location!",
    fileInUse: "This file is in use!",
    backToHome: "Back",
    basic_plan: "Basic plan",
    bronze_plan: "Bronze Plan",
    silver_plan: "Silver Plan",
    gold_plan: "Gold Plan",
    planInfo: "Plan Info",
    yourPlan: "Your Plan",
    months: "Months",
    adValidityPeriod: "Ad validity period",
    monthlyAdCount: "Number of ads that can be created",
    monthlyEditCount: "The number of edits that can be done",
    viewPricing: "View Pricing",
    youCantAddAd: "You can't add a new ad!",
    youCantEditAd: "You cannot edit the ad!",
    planLimitationDescription: "According to your plan, it is not possible to do this, please see the tariffs and prepare your suitable plan!",
    expired_at: "Until the ad expires",
    yourPlanDoesntSupportThis: "Your plan does not support this",
    yourPlanDoesntSupportThisDesc: "Upgrade your plan to use this feature",
    manageFields: "Manage Fields",
    fieldName: "Field Name",
    fieldArabicName: "Field Arabic name",
    fieldDescription: "Field description",
    fieldDescriptionArabic: "Field Arabic description",
    addField: "Add Field",
    makeField: "Create field",
    editField: "Edit Field",
    adminHasFullAccess: "The Admin has full access!",
    pleaseAdCategoryFirst: "Please select a category first!",
    specificFields: "Specific fields",
    totalVisitsCount: "Total Visits",
    todayVisitsCount: "Today's visits",
    totalCommentsCount: "Comments",
    noLogFound: "No results found",
    allowed_comments: "Is it allowed to post comments?",
    allowed: "Allowed",
    not_allowed: "Not allowed",
    expired: "Expired",
    equalItem: "Arabic Equal",
    addEqual: "Add Equal",
    noEqual: "There is no equal item",
    emptyTableCells: "Table is empty",
    AddTable : "Create a Table",
    EditTable : "Edit Table",
    TableBuilder:"Table Builder",
    tableName: "Table name",
    makeTable: "Create Table",
    chooseTableStructure:"Choose Table Columns and Rows",
    columns:"Columns",
    rows:"Rows",
    addRows:"Add Row",
    addColumns:"Add Column",
    buildTable:"Build Table",
    withHeading:"Table With Heading",
    withoutHeading:"Tale Without Heading",
    tableTdPlaceholder:"Type your Content...",
    shortcode:"Short Code",
    insertTable:"Insert Table",
    manageCategoriesOrder: "Manage the order of categories",
    editSubMenuOrder:"Submenu Order",
    noCategoryFound: "Category not found!",
    youAreEditingParentCategoriesOrder:"You are editing parent categories!",
    youAreEditingCategoriesOrder:"Sorting subcategories of: ",
    youAreEditingParentCategoriesOrderDesc:"Order each category's subcategory by clicking the edit button.",
    update:"Update",
    updateAdReal:"Update Ad",
    updateAdRealDesc: "You can update your ad to be in a higher position in the list of ads!",
    advertisementUpdated:"Advertisement updated!",
    rate:"Rate",
    myBusiness: "Manage my Business",
    businessAdded: "Your business has been created.",
    businessAddingFailed: "There was a problem adding business.",
    businessEdited: "Your business has been edited.",
    businessEditingFailed: "There was a problem editing the business.",
    editBusiness:"Edit Business",
    makeBusiness: "Create Business",
    businessName: "Business Name",
    businesses: "businesses",
    business: "Business",
    businessesList:"List of businesses",
    viewBusiness:"View Business",
    businessLogo: "Business Logo",
    yourBusinessIsActive: "Congratulations! Your business is verified and active.",
    yourBusinessIsInchecking: "Your business is being checked!",
    businessBanners: "Business Banners",
    foundingYear: "Establishment Year",
    businessVideos: "Videos",
    manageBusinesses: "Manage Businesses",
    businessBio: "Business Brief Description",
    primaryBusinessImage: "Business Primary Image",
    youCantAddBusiness: "Unable to add business!",
    pageInfo: "Page Info",
    remove: "Remove",
    deleteIt: "Delete",
    active: "Active",
    activeStatus: "Status",
    addSlideShow: "Add Slideshow",
    chooseAdForSlide: "Choose Ad for slide",
    banner_image: "Banner image",
    addBanner: "Add banner",
    editBanner: "Edit banner",
    pageTitle: "Page Title",
    addPage: "Add page",
    makePage: "Create page",
    editPage: "Edit page",
    viewPage: "View page",
    youCantDeletePermanentPages: "Can't delete permanent pages!",
    manageFAQ: "FAQs",
    addFAQ: "Add FAQ",
    editFAQ: "Edit FAQ",
    question: "Question",
    answer: "Answer",
    editDiscount: "Edit discount",
    makeDiscount: "Make discount",
    discountName: "Discount Title",
    discounts: "Discounts",
    discount: "Discount",
    discountsList: "Discounts list",
    viewDiscount: "View discount",
    manageDiscounts: "Manage Discounts",
    myDiscounts: "My Discounts",
    discountAdded: "Your discount has been created.",
    discountAddingFailed: "There was a problem creating the discount.",
    discountEdited: "Your discount has been edited.",
    discountEditingFailed: "There was a problem editing the discount.",
    deleteDiscount: "Discount deleted!",
    deleteDiscountFailed: "There was a problem deleting the discount!",
    discountInfo: "Discount Info",
    primaryDiscountImage: "Primary Discount Image",
    discountGallery: "Image Gallery",
    discountDescription: "Discount Information",
    requestToDeleteDiscount: "request to delete discount",
    removeDiscount: "Remove discount",
    choose: "Choose",
    discount_percent: "Discount percentage",
    priceDiscount: "Actual amount",
    discount_price: "Discount price",
    inventory: "Inventory",
    discount_expire_time: "Discount expiration time",
    dateShouldBeAfterToday: "The time is wrong!",
    discount_expired_at: "Until the discount expires",
    manageDiscountsSlider: "Manage Discounts Slider",
    bannerTitle: "Banner Title",
    wrongPercent: "Wrong percentage",
    discount_banner_1: "Single discount page banner 1",
    discount_banner_2: "Single discount page banner 2",
    bannerPosition: "Banner Position",
    sliderTitle: "Slide Title",
    primary_button_text:'Primary button text',
    primary_button_icon:'Primary button icon',
    primary_button_link:"Primary button link",
    manageProductsComments:"Products Comments",
    manageBusinessesComments:"Businesses Comments",
    manageDiscountsComments:"Discounts view",
    ad:"Advertisement",
    chooseItemForComment:"Choose item for comment",
    noItem:"No item found!",
    term_of_use: "Terms & conditions",
    articles: "Articles",
    manageContents: "Manage Blog",
    contentsList: "List of Posts",
    makeContent: "Create Post",
    manageBlogCategories:"Blog Categories",
    manageBlogComments:"Manage comments",
    contents: "Texts",
    addContent: "Create new post",
    viewContent: "View previous posts",
    editContent: "Edit post",
    contentTitle: "Post Title",
    published: "Published",
    draft: "Draft",
    publishStatus: "Publish Status",
    viewArticle: "View Post",
    relatedAds: "Related ads",
    chooseAd: "Choose ad",
    addAd: "Add Ad",
    tickets: "Support Tickets",
    manageMessages: "Manage Messages",
    manageTickets: "Manage Messages",
    manageDepartments: "Manage Departments",
    requestType: "Request type",
    supportRequestFromDepartment: "System Support",
    supportRequestForProduct: "Product support",
    sendFile: "Send file",
    ticket_sender: "Sender",
    ticket_receiver: "Recipient",
    ticket_title: "Topic",
    ticket_status: "Status",
    ticket_edited_at: "Last posted",
    sendMessage: "Send message",
    backToTickets: "Back to Messages",
    deleteTicket: "Ticket deleted!",
    deleteTicketFailed: "Failed to delete ticket!",
    ticket_details: "Ticket Details",
    ticket_sent: "Ticket sent!",
    sendFastTicket: "Send fast ticket",
    sendMessageTo: "Send message to",
    addTicket: "Compose Ticket",
    send: "Send",
    messageText: "Message text",
    gotToTicketPage: "Messages page",
    removeRequest: 'Remove request',
    related_admin: "Related admin",
    department: "Department",
    departments: "Departments",
    low: "low",
    medium: "Medium",
    high: "high",
    subject: "Subject",
    message: "Message",
    priority: "Priority",
    unread: "Awaiting review",
    read: "Read",
    sentTicket: "Sent",
    sent: "Sent",
    referToMe: "Referred to me",
    resumeOrder: "Continue Order",
    bill: "Bill",
    checkout: "Order completion",
    totalPrice: "Total Price",
    productsInCart: "Products in cart",
    totalCartPrice: "Cart Total",
    totalUserDiscounts: "Total discounts",
    totalDiscounts: "Your profit",
    makeOrder: "Make order",
    orderPayed: "Order is paid.",
    orderPayingFailed:"There is a problem in paying the amount!",
    pending: "Awaiting approval",
    not_paid:"Pending payment",
    viewOrders: "View Orders",
    viewOrdersDetails: "View all your past orders from this section!",
    viewOrder: "View order",
    orders: "Purchases",
    ordersList: "My orders List",
    orderDetails: "Order Details",
    productsList: "List of products",
    purchase: "Payment",
    payableMoney: "Invoice price",
    payOrder: "Pay Order",
    order_status: "Order status",
    factor_number: "Invoice number",
    factor_status: "Invoice status",
    created_at_factor: "Order date",
    mobileNumber: "Mobile Number",
    status: "Status",
    payed: "Paid",
    not_used: "not used",
    used: "Used",
    unique_key: "Unique code",
    not_use: "Not used",
    withoutExpire: "Without expiration",
    manageOrders: "Manage Orders",
    dataRemoved: "Data removed successfully",
    dataRemovingFailed: "There was a problem removing data.",
    buyer: "Buyer",
    doesntHave: "Doesn't have",
    dateShouldBeAfterExpire: "The expiry time is wrong!",
    expire_after_purchase: "Expiration",
    businessContactInfo: "Contact Information",
    manageBusinessTypes:"Manage Business Types",
    uniqueKey:"Unique key",
    business_type_name:"Type name",
    business_arabic_type_name:"Arabic Type name",
    business_type:"Business Type",
    isThisFieldRequired:"This field is required!",
    field_type:"Field type",
    text:"Text",
    long_text:"Long text",
    select_field:"Select box",
    multi_select_field:"Multi Select box",
    options:"English Options",
    arabic_options:"Arabic Options",
    pressEnterToMakeEachOption:"Press Enter to create each option",
    businessFields:"Business Fields",
    productFields:"Products Fields",
    faqFields:"RFQ Fields",
    pleaseChooseABusinessTypeForFields:"Select the type of business to specify the fields related to it.Otherwise, you can see the global fields.",
    globalFields:"Global Fields",
    fields:"Fields",
    total_employees:"Total Employees",
    total_annual_revenue:"Total Annual Revenue",
    position_type:"Field Position",
    trade_capacity:"Trade Capacity",
    product_capacity:"Product Capacity",
    units:"English Units",
    arabic_units:"Arabic Units",
    pleasChooseBusinessTypeFirst:"Please choose business type first.",
    contactPersonAvatar:"Contact person photo",
    contact_person_name:"Contact person name",
    contact_person_position:"Contact person position",
    certificates:"Certificates",
    addCertificate:"Add Certificates",
    editCertificate:"Edit Certificate",
    certificate:"Certificate",
    working_days_times:"Working Days & Times",
    businessDescription:"Business Description",
    businessTypes:"Business types",
    makeProduct:"Create a Product",
    manageProducts:"Manage Products",
    product:"Product",
    shortName:"Short name",
    iconImage:"Icon image",
    productAdded: "Your product has been created.",
    productAddingFailed: "There was a problem creating the product.",
    productEdited: "Your product has been edited.",
    productEditingFailed: "There was a problem editing the product.",
    deleteProduct: "Product deleted!",
    deleteProductFailed: "There was a problem deleting the product!",
    youCantAddProduct: "You can't add a new product!",
    yourProductIsActive: "Congratulations! Your product is verified and active.",
    yourProductIsInchecking: "Your product is being checked!",
    viewProduct: "View Product",
    availableProducts: "Available Products",
    productName: "Product name",
    moq: "MOQ",
    moqDesc: "Minimum Order Quantity",
    payment_method: "Payment method",
    primaryProductImage: "Product image",
    productGallery: "Product Gallery",
    company_address: "Company Address",
    productDescription: "Product Description",
    openControl: "show Controls",
    originCountry: "Origin country",
    attachments: "Attachments",
    addFile: "Add File",
    companyAddress: "Company Address",
    depotAddress: "Warehouse Address",
    hasDepotLocation: "The Warehouse of this product is in another location",
    hasNoDepotLocation: "The Warehouse of this product is in the same as the company location",
    removeProduct:"Remove Product",
    requestToDeleteProduct: "Request to delete Product",
    pleaseChooseACategoryForFields:"Select the category to specify the fields related to it.Otherwise, you can see the global fields.",
    packaging_delivery:"Packaging & Delivery",
    essential_details:"Essential Details",
    supply_ability:"Supply Ability",
    product_details:"Product Details",
    pleasChooseCategoryFirst:"Please choose category first.",
    priceIsLessThanDiscount:"Price is less than discount price",
    editProduct:"Edit Product",
    productInfo:"Product Information",
    manageRFQs:"Manage RFQs",
    myRFQs:"My RFQs",
    wanted:"Wanted",
    destination:"Destination",
    rfqList:"RFQs List",
    rfqs:"RFQs",
    rfq:"RFQ",
    rfqAdded: "Your RFQ has been created.",
    rfqAddingFailed: "There was a problem creating the RFQ.",
    rfqEdited: "Your RFQ has been edited.",
    rfqEditingFailed: "There was a problem editing the RFQ.",
    deleteRFQ: "RFQ deleted!",
    deleteRFQFailed: "There was a problem deleting the RFQ!",
    yourRFQIsActive: "Congratulations! Your RFQ is verified and active.",
    yourRFQIsInchecking: "Your RFQ is being checked!",
    viewRFQ: "View RFQ",
    rfqInfo: "RFQ Information",
    makeRFQ:"Create a RFQ",
    editRFQ:"Edit RFQ",
    rfqName:"Looking for",
    rfqNameDesc:"What product do you want?",
    destinationCountry:"Destination",
    buyerFrom:"Buyer from",
    quantityRequirement:"Quantity Requirement",
    payment_terms:"Payment terms",
    shipping_terms:"Shipping terms",
    rfqDescription:"RFQ Description",
    requestToDeleteRFQ: "Request to delete RFQ",
    removeRFQ: "Remove RFQ",
    RFQFields: "RFQ Fields",
    rfq_custom_fields: "RFQ Custom Fields",
    youCantAddRFQ: "You can't add a new RFQ!",
    availableRFQs: "Available RFQs",
    leadtimeImage: "Lead Time Image",
    productExamples: "Product Examples",
    average_rating: "Average Rate",
    product_side_banner: "Product side banner",
    simple_user: "User",
    removeBusiness: "Remove Business",
    ShowCaseProducts: "Showcase Products",
    main_products: "Main Products",
    other_products: "Other Products",
    addToMain: "Add to Showcase",
    main_product: "Showcase",
    noProductFound: "No Product found!",
    bannerSize: "Banner Size should be 1200x300 px",
    business_home_banner: "Business Home Banner",
    products: "Products",
    businessGallery: "Business Gallery",
    gallerySize: "Media Width should be at least 600px",
    mainProducts: "Main Products",
    rfq_top_banner: "RFQ Top Banner",
    rfq_expired_at: "RFQ expires on",
    rfq_expire_time: "RFQ expiration time",
    homeTopSlideShow: "Homepage top slideshow",
    homeMiddleSlideShow: "Homepage Middle slideshow",
    home_middle_slider_delay: "Homepage middle slideshow delay",
    home_top_slider_delay: "Homepage top slideshow delay",
    changeDelayTime: "Change delay time",
    home_left_banner: "Home left banner",
    registerHint: "Tell us little about your business to get connected \n with millions of buyers and suppliers.",
    messages: "Messages",
    changeMobileOrEmail: "Change mobile number or email",
    changeMobileOrEmailDesc:"If the information changes, reconfirmation is required.",
    anotherUserHasThisInfo:"The entered information has already been registered by another user.",
    chooseOwnerFile:"File Owner",
    isThisProductPopular:"Is This Product Popular?",
    imageProperWidthHeight: "Proper Width & Height",
    imageProperSize: "Maximum image size",
    videoProperSize: "Maximum video size",
    ok: "Ok",
    per: "Per",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    off: "Closed",
    start_time: "Start Time",
    end_time: "End Time",
    showcase_products: "Product Showcase",
    duplicateProductName: "You have already created a product with this name.",
    relatedUser:"Related user",
    chooseAUser:"Choose a user!",
    relatedUserBusiness: "Related User / Business",
    YouCantAddProductBecauseOfBusiness: "To create a Product, Please create your business page first.",
    inquiryRequests: "Inquiry requests",
    contact: "Contact",
    rfqsMessages: "RFQs Messages",
    productsMessages: "Products Messages",
    businessesMessages: "Businesses Messages",
    sample_request: "Sample for",
    sampleRequests: "Sample requests",
    provider: "Provider",
    isThisProductVerifiedSample: "Is this product sample verified?",
    sample: "Sample request",
    system: "System Ticket",
    isThisFieldInCompare: "Shown on the compare page",
    isThisBannerActive: "Is This banner active?",
    blog: "Blog",
    active_people: "Hot Stories",
    simple: "Simple",
    articlesGallery: "Post Gallery",
    makeBlogCategory: "Create Blog Category",
    editBlogCategory: "Edit Blog Category",
    equalCategory:"Arabic Equal",
    is_featured_article:"Is this post among the top Posts?",
    bannerSizeContent: "Image Size  1300x600px",
    newsletterSubscribers:"Subscribers",
    pageCover:"Page Cover",
    blog_bottom_banner:"Blog Bottom Banner",
    description:"Description",
    title:"Title",
    about_page_banner_1:"About Page Card 1",
    about_page_banner_2:"About Page Card 2",
    about_page_banner_3:"About Page Card 3",
    about_page_banner_4:"About Page Card 4",
    main_fax:"Website Fax number",
    FAQCategory:"Category",
    faq_categories:"FAQ Categories",
    help_items:"Help Items",
    pleaseChooseAFile: "Please choose a file",
    yourInfoIsUnderChecking: "Your information is being reviewed",
    yourInfoIsUnderCheckingDesc: "After checking by site experts, you will be informed about the result of registration",
    confirmRegisterInfo: "Confirm registration information",
    rejectRegisterInfo: "Information rejected",
    areInfosConfirmed: "User registration information is confirmed",
    faults: "Registration form faults",
    registerInfo: "Register info",
    registerInfoNeedApprovalTitle: "Check registration information",
    registerInfoNeedApproval: "The user's registration information needs to be checked, please check and approve or reject the items",
    yourInfoIsVerified: "You have been verified successfully ",
    yourInfoIsVerifiedDesc: "Your information has been verified and you have been assigned a Lite plan.",
    yourInfoIsChecked: "Your registration information has been checked",
    pleaseReadAndDo: "Please read the following and edit your information",
    editInfo: "Edit Info",
    managePlanParents: "Plans Groups",
    plans: "Plans",
    makePlanParent: "Create Plan Parent",
    planName: "Plan Name",
    planArabicName: "Arabic plan name",
    arabic_description: "Arabic description",
    editParent: "Edit Plan Group",
    makeParent: "Create Plan Group",
    planFeatures: "Plan Features",
    planArabicFeatures: "Plan Arabic features",
    managePlans: "Manage Plans",
    days: "day",
    editPlan: "Edit Plan",
    makePlan: "Create Plan",
    planParent: "plan group",
    discountPrice: "discounted price",
    durationPlan: "Duration of Validity",
    planValidity: "Valid until",
    viewPlans: "View plans",
    myPlan: "My Plan",
    reserved_plan: "Reserved plan",
    planDuration: "Duration of the plan",
    forPurchasingBetterPlansAdsAndReserveGoToPlans: "You can visit plans page to upgrade your plan",
    plansPage: "View Plans",
    userPlanInfo: "User plan information",
    givePlanToUser: "Give the plan to the user",
    choosePlan: "Choose plan",
    plan: "Plan",
    changeColor: "Change Color",
    color: "Color",
    make_products: "Number of products to create",
    answer_to_rfq: "Number of weekly Answers to RFQs",
    verify_badge: "Verify Badge",
    has_products_top_list: "Products categories top list",
    show_in_popular: "Show products on the popular products",
    has_commercial_banner: "Commercial banner in different parts of the site",
    has_banner_design: "Exclusive banner design for business page",
    has_business_page: "Business page with special features",
    has_products_showcase: "Products showcase",
    has_business_catalogue: "Upload the company Catalog",
    has_business_video: "Upload the company Video",
    has_business_image: "Upload the company Image",
    has_certificates: "Upload the company Certificates",
    has_send_rfq: "The possibility of posting RFQ",
    has_receive_public_rfq: "Receiving public RFQ",
    has_receive_private_rfq: "Receiving private RFQ",
    has_backlink: "Link to verified buyers",
    has_social_media_marketing: "Social media marketing",
    has_support: "24/7 support and Chat",
    has_youtube: "Upload video from Youtube",
    step1: "Initial registration",
    step2: "Complete the form",
    step3: "Upload documents",
    step4: "Awaiting approval",
    step5: "Requires modification",
    step6: "Confirmed",
    license_number: "License Number",
    license_expiration_date: "license expiration date",
    withRegisteringAnAccount: "By creating an account,You are agree with ",
    termsAndConditions: "Terms and Conditions",
    and: " and ",
    saveAndContinue: "Save & continue",
    companyName: "Company name",
    uploadAtLeast3items: "Please select and upload at least 3 documents from the list below",
    documentName: "Document name",
    dateAdded: "Date added",
    verified: "Verified",
    companyDocuments: "Company documents",
    documentCategory: "Document Category",
    uploadDocument: "Upload document",
    chooseFile: "Choose file",
    upload: "Upload",
    duplicated_document: "Please select different documents",
    congratulation: "Congratulation",
    buyPlan: "Buy Plan",
    register_completed_text: "Register Completed text",
    month: "months",
    not_payed:"Pending payment",
    myTransactions:"Transactions",
    paymentManagement: "payment management",
    amount: "Amount",
    transactionType: "Transaction Type",
    withdrawMoney: "Deposit request",
    withdrawMoneyDescription: "Enter the amount you want to deposit.",
    deposit:"Charge",
    withdraw: "Withdrawal",
    manageWallets: "Manage Transactions",
    manageWallet: "Manage Transactions",
    balance: "Balance",
    manageUserWallet: "Manage user transactions",
    totalResponses: "Total responses",
    viewTicket: "View Ticket",
    productsMyList: "My Products Lists",
    rfqMyList: "My RFQs List",
    isThisProductHasSample: "Do you have a sample of this product to provide to the customer?",
    ticket: "Ticket",
    ticketType: "Ticket Type",
    chooseARFQ: "Choose RFQ",
    infinite: "Infinite",
    businessCatalog: "Business Catalog",
    becomePremium: "Become premium",
    staringDate: "start Date",
    viewRegisterInfo: "View registration information",
    viewRegisterInfoHint: "You can see your registration information in this section. If you need to change the information, Submit a ticket.",
    terminateRequest: "Request to terminate membership",
    sellerGuidLine: "Guideline",
    submitCompanyInfo: "Submit company info",
    letsGo: "Let's go",
    enjoyPremiumFeatures: "Premium Services",
    createRFQ: "Create a RFQ",
    createProduct: "Create a Product",
    myProducts: "My products",
    upgradeYourPlan: "Upgrade your plan",
    companyPage: "Company page",
    createBusinessPageToSee: "Create a business page to see its information here",
    upgradeYourPlanToCreateProducts: "Upgrade your plan to create products",
    numberOfProducts: "Number of Products",
    numberOfRFQs: "Number of RFQs",
    viewList: "View list",
    totalAnswersToYourRFQs: "Answers to my RFQs",
    visitsChart: "Visits chart",
    liveUsers: "Live users",
    newProducts: "Today's new Products",
    todayRFQs: "Today's new RFQs",
    newRegistrations: "Today's New Users",
    todayAllUnRegisteredUsers: "Today's all visitors",
    invalidEmail: "This Email does not exist.",
    recoverByEmail: "Recover with email address",
    recoverByMobile: "Recover with mobile number",
    recoverPasswordTextEmail:"Enter your Email address to recover your password.",
    receiver:"Receiver",
    ifYouWantToSeeMoreInfoClick: "If you want to see the full information of this page",
    clickHere: "Click Here",
    attentionText: "Please note that if you click, you will enter the English panel",
    expirationDate: "Expiration",
    youAreViewingUserRegisterInfo: "Dear admin, you are viewing user registration information",
    registerInfoDone: "You can edit user register information in this section",
    editDetailss: "Edit details",
    youCanEditUserRegisterInfo: "To view and edit user register info use this button",
    ordersListAdmin: "Orders List",
    userType: "User type",
    verifiedUser: "Verified User",
    verifiedUsers: "Verified Users",
    normalUser: "Normal user",
    completingForm: "Completing",
    verified_sample_text: "Verified sample text",
    productGallerySize: "Image Size should be 800x600 px",
    weakPassword: "The password entered is weak",
    noMatchPassword: "Confirmation is not the same as password",
    monthlySales: "This Month",
    totalSale: "Total",
    plansSales: "Plan orders",
    users: "Users",
    pageCoverSize: "The best size is 1550px x 300px  (other sizes are fine)",
    fileSize: "Size",
    userSuspensionStatus: "User Suspension",
    userSuspensionStatusDescription: "From this section, you can set the status of the user's registration and plan to suspended",
    suspended: "Suspended",
    yourAccountIsSuspended: "Your account is suspended",
    linkedinLink: "Linkedin Link",
    linkedinPlaceHolder: "https://linkedin.com/your_page",
    changeCover: "Change preview",
    keywordsLabel: "Keywords",
    allKeywordsAreTyped: "You cannot add more keywords.",
    keywordsDescription: "Write related tags.",
    pressEnterAfterEachKeyword: "Press the Enter button after writing each keyword!",
    deleteBusiness: "Business deleted!",
    deleteBusinessFailed: "There was a problem deleting the business!",
    deleteDataFailedPlanParent: "Please delete the plans of this group first.",
    userCurrentStep: "Current step",
    free: "Free",
    confirm_payment: "Payment confirmation",
    transactionTrackingCode: "Transaction Tracking Code",
    step2Text: "Complete the form: If you want to have a business and product page",
    step2Text2: "Complete the form: If you want to be a verified buyer",
    rfqGuidelineText: "Create your buy requirement for free.",
    reserveSituation: "How to activate new plan",
    do_reserve: "Reserve plan until the end of the current plan",
    do_renew: "Replaces the current plan now",
    manageBusinessTypesOrder: "Manage the order of Business types",
    meta_title : "Meta Title" ,
    makePageNoIndex : "Do not display to search engines (No Index)" ,
    seoDetails : "Manage SEO" ,

};
