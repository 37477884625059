import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../../components/editor/RichEditor";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import Thumbnail from "../../fileManager/Components/Thumbnail";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import SubTitle from "../../../components/elements/SubTitle";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import FieldCounter from "../../../components/elements/FieldCounter";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Button from "@material-ui/core/Button/Button";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import {makeSlug, translate} from "../../../../config/lang";
import CategoryEqualCard from "./CategoryEqualCard";
import SingleImageChooser from "../../fileManager/Components/SingleImageChooser";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import MetaDescription from "../../fileManager/Components/MetaDescription";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

class MakeCategory extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();

    }
    state = {
        fields:{
            name:"",
            short_name:"",
            description:"",
            meta_title:"",
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: 1,
        icon: null,
        errors:{},
        categories:[],
        defaultCategory:null,
        slug:null,
        loading: true,
        SubmitLoading: false,
        confirmed: true,
        hide_products: true,
        parentId:null,
        no_index:0,
        id:this.props.match.params.id,
        editMode:false,
        category:null,
        equal:null,
    };
    classes = this.props.classes;
    async componentDidMount(){
        if(!!this.state.id){
            this.setState({
                editMode:true
            })
            await this.loadData();
        }
        await this.loadParents();
    }
    async loadParents(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/categories/quickViewForChooser/${this.props.locale}`, config)
                .then(response => {
                    let categories = response.data.filter((item) => {
                        return item.id !== parseInt(this.id)
                    });
                    categories.map((item,index) =>{
                        return item.id === this.state.parentId ? this.setState({
                            defaultCategory: index,
                        }) : null
                    })
                    this.setState({
                        categories: response.data,
                        loading:false,
                        SubmitLoading:false
                    })
                })
                .catch(error => {
                    this.setState({
                        loading:false,
                        SubmitLoading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false,
                SubmitLoading:false
            });
            this.setState({
                loading:false,
                SubmitLoading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/category/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            short_name: !!response.data.short_name ? response.data.short_name : '',
                            description: !!response.data.description ? response.data.description : '',
                            meta_title: !!response.data.meta_title ? response.data.meta_title : '',
                        },
                        thumbnail: response.data.thumbnail.id,
                        icon: response.data.icon,
                        parentId: response.data.parent_id,
                        confirmed: response.data.confirmed,
                        hide_products: response.data.hide_products,
                        slug: response.data.slug,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                        equal: response.data.equal,
                        category: response.data,
                        no_index: response.data.no_index,
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = translate('maxLength150');
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    async reload(){
        await this.setState({
            loading:true
        })
        await this.loadData();
        await this.setState({
            loading:false
        })
    }
    handleRequest(){
        let { fields,thumbnail,no_index,content,id,editMode,confirmed,hide_products,parentId,icon} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('short_name', fields.short_name);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('meta_title', fields.meta_title);
        data.append('icon', !!icon ? icon.id : 9);
        data.append('thumbnail', thumbnail);
        data.append('parent_id', parentId);
        data.append('lang', this.props.locale);
        data.append('confirmed',  confirmed ? 1 : 0);
        data.append('hide_products',  hide_products ? 1 : 0);
        data.append('no_index',  !!no_index ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/category/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('dataEdited') : translate('dataAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
                this.props.history.push(`/manage/categories`);
                if(!this.state.editMode){

                }
            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 403){
                    this.props.enqueueSnackbar(translate('categoryPublishedBefore'), {
                        variant: 'error',
                    });
                }else{
                    this.props.enqueueSnackbar(editMode ?  translate('dataEditingFailed') : translate('dataAddingFailed'), {
                        variant: 'error',
                    });
                }

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeHideProducts(){
        this.setState({
            hide_products: !this.state.hide_products,
        })
    }
    handleParent(value){
        if(value === null){
            this.setState({
                parentId:''
            })
        }else{
            this.setState({
                parentId:value.id
            })
        }
    }
    handleIconImage(icon){
        this.setState({
            icon
        })
    }
    render(){
        let {fields, errors,SubmitLoading,thumbnail,content,editMode,confirmed,equal,category,slug,categories,icon} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editCategory') : translate('makeCategory')}>
            {
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} lg={11}>
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12} md={8}>
                                        <AppCard
                                            title={editMode ? translate('editCategory') : translate('makeCategory')}
                                            action={
                                                <BreadCrumbs singleLevel firstText={editMode ? translate('editCategory') : translate('makeCategory')}/>
                                            }>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} justify="center" alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Alert
                                                                severity="info"
                                                                action={
                                                                    <FormControlLabel
                                                                        style={{marginRight:10}}
                                                                        control={
                                                                            <Switch
                                                                                checked={confirmed}
                                                                                onChange={this.handleChangeConfirmed.bind(this)}
                                                                                value={confirmed}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                    />
                                                                }
                                                            >
                                                                {translate('confirmationStatusDescription')}
                                                            </Alert>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                color="secondary"
                                                                variant="outlined"
                                                                name="name"
                                                                label={translate('categoryName')}
                                                                type="text"
                                                                id="name"
                                                                value={fields.name}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['name']}
                                                                helperText={errors['name']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                color="secondary"
                                                                name="short_name"
                                                                label={translate('shortName')}
                                                                type="text"
                                                                id="short_name"
                                                                value={fields.short_name}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['short_name']}
                                                                helperText={errors['short_name']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Autocomplete
                                                                options={categories}
                                                                color="secondary"
                                                                defaultValue={categories[this.state.defaultCategory]}
                                                                onChange={(event, value) => this.handleParent(value)}
                                                                getOptionLabel={option => option.name}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        margin="normal"
                                                                        {...params}
                                                                        color="secondary"
                                                                        label={translate('categoryParentName')}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <RichEditor ref={this.EditorChild} Output={this.handleOutPut.bind(this)} input={content.RAW}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                margin="normal"
                                                                multiline
                                                                fullWidth
                                                                name="description"
                                                                color="secondary"
                                                                label={translate('seoDescription')}
                                                                placeholder={translate('seoDescription')}
                                                                type="text"
                                                                id="description"
                                                                rows="4"
                                                                value={fields.description}
                                                                onChange={this.handleChange.bind(this)}
                                                                onClick={this.handleClickDescription.bind(this)}
                                                                error = {!!errors['description']}
                                                                helperText={errors['description']}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                        {/*<Grid item xs={12}>
                                                    <Alert
                                                        severity="warning"
                                                        variant="outlined"
                                                        action={
                                                            <FormControlLabel
                                                                style={{marginRight:10}}
                                                                control={
                                                                    <Switch
                                                                        checked={hide_products}
                                                                        onChange={this.handleChangeHideProducts.bind(this)}
                                                                        value={hide_products}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={hide_products ? translate('yes : translate('no}
                                                            />
                                                        }
                                                    >
                                                        {translate('doYouWantToHideProducts}
                                                    </Alert>
                                                </Grid>*/}
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={this.state.no_index} onChange={() => this.setState({no_index:!this.state.no_index})} />}
                                                                    label={translate('makePageNoIndex')}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                margin="normal"
                                                                name="meta_title"
                                                                variant="outlined"
                                                                label={translate('meta_title')}
                                                                value={fields.meta_title}
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['meta_title']}
                                                                helperText={errors['meta_title']}
                                                            />
                                                        </Grid>
                                                        <Hidden mdDown>
                                                            <Grid item xs={12}>
                                                                <LoadingButton
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick = {this.handleSubmit.bind(this)}
                                                                    loading = {SubmitLoading}
                                                                >
                                                                    {editMode ? translate('editCategory') : translate('makeCategory')}
                                                                </LoadingButton>
                                                            </Grid>
                                                        </Hidden>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid container spacing={2}>
                                            <Hidden smDown>
                                                <Grid item xs={12} style={{paddingTop: 0}}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        style={{width: "100%"}}
                                                        onClick={this.handleSubmit.bind(this)}
                                                        loading={SubmitLoading}
                                                    >
                                                        {editMode ? translate('editCategory') : translate('makeCategory')}
                                                    </LoadingButton>
                                                </Grid>
                                            </Hidden>
                                            {
                                                !!editMode && !!confirmed ?
                                                    <Button
                                                        component="a"
                                                        target="_blank"
                                                        size="small"
                                                        startIcon={<PageviewRoundedIcon/>}
                                                        href={makeSlug(`products/${slug}`)}
                                                        color="secondary"
                                                    >
                                                        {translate('viewCategory')}
                                                    </Button>
                                                    : null
                                            }
                                            <Grid item xs={12}>
                                                <AppCard>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={12} style={{paddingBottom:15}}>
                                                                    <SubTitle align="left">{translate('ThumbnailTitle')}</SubTitle>
                                                                    <Thumbnail rounded ref={this.ThumbChild}
                                                                               handleThumbImage={this.handleThumbImage.bind(this)}
                                                                               initial={thumbnail}/>
                                                                    <div style={{marginTop:10}}>
                                                                        <MetaDescription
                                                                            width={1000}
                                                                            height={1000}
                                                                            size={"20MB"}
                                                                            primary
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <SubTitle align="left">{translate('iconImage')}</SubTitle>
                                                                    <SingleImageChooser
                                                                        primary
                                                                        logo
                                                                        onChange={(icon) => this.handleIconImage(icon)}
                                                                        initial={icon}
                                                                    />
                                                                    <MetaDescription
                                                                        width={500}
                                                                        height={500}
                                                                        size={"20MB"}
                                                                        primary
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            {
                                                (!this.state.loading && !!editMode) &&
                                                <Grid item xs={12}>
                                                    <AppCard>
                                                        <SubTitle align="left">{translate('equalItem')}</SubTitle>
                                                        <CategoryEqualCard
                                                            equal_id={!!equal ? equal.id : null}
                                                            item={category}
                                                            reload={() => this.reload()}
                                                        />
                                                    </AppCard>
                                                </Grid>
                                            }


                                            <Hidden mdUp>
                                                <Grid item xs={12} style={{paddingTop: 0}}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        style={{width: "100%"}}
                                                        onClick={this.handleSubmit.bind(this)}
                                                        loading={SubmitLoading}
                                                    >
                                                        {editMode ? translate('editCategory') : translate('makeCategory')}
                                                    </LoadingButton>
                                                </Grid>
                                            </Hidden>
                                        </Grid>


                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeCategory))));