export const Ar ={
    ar: "AR",
    en: "EN",
    login: "تسجيل الدخول",
    existEmail: "عنوان البريد الإلكترونى هذا مسجل بالفعل.",
    existMobile: "تم تسجيل رقم الهاتف المحمول هذا بالفعل.",
    emptyEmail: "أدخل عنوان بريدك الالكتروني.",
    emptyMobile: "أدخل رقم هاتفك المحمول.",
    wrongEmail: "تنسيق البريد الإلكتروني خاطئ.",
    wrongMobile: "تنسيق رقم الهاتف المحمول خاطئ.",
    emptyEmailOrMobile: "ادخل بريدك الإلكتروني أو رقم الهاتف النقال",
    loginError: "بريدك الإلكتروني خطأ.",
    emailOrMobile: "البريد الإلكتروني أو رقم الهاتف المحمول",
    first_name: "الاسم الأول",
    last_name: "اسم العائلة",
    company_name: "اسم الشركة",
    phone: "هاتف",
    passwordConfirm: "اعد كلمة السر",
    passwordStrength: "ما لا يقل عن 6 أحرف ، بما في ذلك الأرقام والرسائل",
    passwordChanged: "تم تغيير الرقم السري بنجاح.",
    currentPasswordError: "كلمة المرور الحالية غير صحيحة.",
    currentPassword: "كلمة السر الحالية",
    register: "يسجل",
    doRegister: "انشاء حساب جديد",
    dontHaveAccount: "ليس لديك حساب؟",
    haveAccount: "هل لديك بالفعل حساب معدني B2B؟",
    forgetPass: "هل نسيت كلمة السر",
    loginWithToken: "تسجيل الدخول مع الرسائل القصيرة",
    password: "كلمة المرور",
    backToLogin: "العودة إلى صفحة تسجيل الدخول",
    doLogin: "تسجيل الدخول",
    rules: "الأحكام والشروط",
    withRegistering: "عن طريق التسجيل على هذا الموقع ، أقبله",
    privacyPolicy: "قواعد الخصوصية",
    privacyPolicyConfirmation: ".",
    mobile: "متحرك",
    email: "بريد إلكتروني",
    sms: "رسالة قصيرة",
    verifyEmail: "التحقق من البريد الإلكتروني",
    registerSuccess: "سجل المستخدم بنجاح.",
    registerError: "خطأ في إنشاء المستخدم",
    youLoggedOff: "لقد قمت بتسجيل الخروج",
    mobileVerified: "تم التحقق من رقم هاتفك المحمول.",
    wrongToken: "رقم التحقق خطأ.",
    mobileVerify: "التحقق من الهاتف المحمول",
    verifyMobile: "تحقق من رقم الهاتف المحمول",
    sendTokenText1: "تم إرسال الرمز المميز لمرة واحدة إلى",
    sendTokenText2: ".",
    sendTokenTextResend: "تم استياء كلمة المرور لمرة واحدة لك.",
    sendTokenText3: "الوقت المتبقي لإعادة إرسال الرسائل القصيرة",
    logOut: "تسجيل خروج",
    confirm: "يتأكد",
    token: "رمز التحقق",
    forgetPassword: "هل نسيت كلمة السر",
    invalidMobileNumber: "رقم الهاتف المحمول هذا غير موجود.",
    recoverPassword: "إستعادة كلمة المرور",
    recoverPasswordText: "أدخل رقم هاتفك المحمول لاستعادة كلمة المرور الخاصة بك.",
    sendAgain: "أعد الإرسال",
    changePassword: "تغيير كلمة المرور",
    managePassword: "إدارة كلمة المرور",
    newPassword: "كلمة المرور الجديدة",
    newPasswordConfirm: "كرر كلمة المرور الجديدة",
    loginWithUserPass: "تسجيل الدخول مع البريد الإلكتروني",
    addYourNumberFirst: "أدخل رقم هاتفك المحمول للدخول إلى الموقع.",
    errorHappens: "حدث خطأ أثناء تحميل الموقع!",
    allChangesSaved: "تم حفظ جميع التغييرات.",
    allChangesSavingFailed: "فشل حفظ التغييرات!",
    connectionError: "الإنترنت الخاص بك أسفل.",
    pleaseCheckTheBox: "تأكيد الشروط والأحكام من فضلك!",
    emptyField: "الحقل فارغ!",
    makingTokenError: "هناك مشكلة في إنشاء رمز التحقق.",
    editMobile: "تحرير رقم الهاتف المحمول",
    editMobileDesc: "إذا كنت تريد تغيير رقم هاتفك المحمول (",
    editMobileDesc2: ")، ادخال.",
    save: "يحفظ",
    dataAdded: "تم إنشاء بياناتك.",
    dataAddingFailed: "كانت هناك مشكلة في إنشاء البيانات.",
    emptyPassword: "ادخل رقمك السري",
    unreadNotifications: "الإخطارات غير المقروءة",
    readNotifications: "اقرأ الإخطارات",
    noNotifications: "لا يوجد إخطار!",
    nothingFound: "الصفحة غير موجودة!",
    markAsRead: "ضع إشارة مقروء",
    copyright: "جميع الحقوق محفوظة ©",
    dashboard: "لوحة القيادة",
    account: "إدارة الحساب",
    fileManager: "مدير الملفات",
    myDetails: "حسابي",
    myAccount: "حسابي",
    doYouWanaExit: "هل تريد الخروج؟",
    readMore: "اقرأ أكثر",
    areYouSure: "هل أنت متأكد؟",
    yes: "نعم",
    no: "لا",
    userPanel: "لوحة",
    userMobileIsConfirmed: "تم تأكيد رقم الهاتف المحمول للمستخدم.",
    userMobileIsNotConfirmed: "لم يتم تأكيد رقم الهاتف المحمول للمستخدم.",
    doYouWanaConfirm: "هل تؤكد رقم الهاتف المحمول للمستخدم؟",
    loginToUserAccount: "تسجيل الدخول إلى حساب هذا المستخدم",
    userShouldConfirmMobileFirst: "يجب تأكيد رقم الهاتف المحمول للمستخدم أولاً!",
    youAreInSomeOneAccount: "أنت في حساب شخص آخر!",
    backToMyAccount: "خلف",
    setDetails: "حفظ التغييرات",
    detailsSet: "تم تسجيل المعلومات.",
    detailsSetFailed: "كانت هناك مشكلة في تسجيل المعلومات.",
    detailsGetFailed: "كانت هناك مشكلة في الحصول على المعلومات.",
    someFieldsHasErrorPleaseCheckThem: "واحد أو أكثر من الحقول لديها أخطاء ، يرجى التحقق منها",
    someFieldsHasError: "واحد أو أكثر من الحقول لديها أخطاء ، يرجى التحقق",
    maxLength70: "يجب ألا يتجاوز طول هذا الحقل 70 حرفًا.",
    maxLength150: "يجب ألا يتجاوز طول هذا الحقل 150 حرفًا.",
    maxLength1500: "يجب ألا يتجاوز طول هذا الحقل 1500 حرفًا.",
    maxLength255: "يجب ألا يتجاوز طول هذا الحقل 255 حرفًا.",
    maxLength500: "يجب ألا يتجاوز طول هذا الحقل 500 حرف.",
    maxLength1000: "يجب ألا يتجاوز طول هذا الحقل 1000 حرف.",
    chooseMedia: "اختر ملف",
    submitErrorFile: "هناك مشكلة في الملف المقدم.",
    imageSizeWidth700: "العرض الصحيح للصورة هو 700 بكسل.",
    thumbnailSize: "الحد الأدنى عرض الصورة: 600 بكسل",
    userAvatarSet: "تم تحديث صورة ملفك الشخصي.",
    profileImage: "صورة الملف الشخصي",
    userAvatarSetFailed: "كانت هناك مشكلة في تغيير صورة الملف الشخصي.",
    loadingThumbnailFailed: "كانت هناك مشكلة في تحميل الصورة",
    ChooseImageLink: "لنسخ الرابط ، اختر الحجم المطلوب.",
    deleteFile: "تم حذف الملف بنجاح",
    deleteFileFailed: "كانت هناك مشكلة في حذف الملف.",
    deleteSelectedFiles: "تم حذف الملفات بنجاح",
    alt: "نص بديل (Alt)",
    chooseImage: "اختر صورة",
    emptyImage: "اختر صورة!",
    descriptionPlaceHolder: "اكتب الوصف ذي الصلة",
    words: "كلمة",
    characters: "شخصية",
    uploadImage: "تحميل الوسائط",
    ThumbnailTitle: "الصورة الرئيسية",
    loading: "تحميل...",
    makePassword: "جعل كلمة المرور",
    addImage: "إضافة صورة",
    imageName: "اسم الملف",
    ownerMedia: "الناشر",
    insertMedia: "أدخل الملف",
    insert: "إدراج",
    linkCopied: "تم نسخ الرابط.",
    uploadSuccess: "كان التحميل ناجحًا",
    editFile: "تم تحرير الملف بنجاح",
    editFileFailed: "واجه تحرير الملف مشكلة.",
    tableAdded: "تم إنشاء الجدول",
    tableAddingFailed: "فشل إضافة جدول.",
    deleteTable: "تم حذف الجدول بنجاح",
    deleteTableFailed: "كانت هناك مشكلة في حذف الجدول.",
    tableEdited: "تم تحرير الجدول.",
    tableEditingFailed: "فشل تحرير الجدول.",
    selfFiles: "ملفاتي",
    adminsFiles: "ملفات الموقع",
    everyBodyFiles: "جميع ملفات المستخدمين",
    uploading: "تحميل ...",
    uploadDone: "ملف مرفوع.",
    allTypes: "كل التنسيقات",
    image: "صورة",
    video: "فيديو",
    document: "وثيقة",
    chooseVideo: "اختر الفيديو",
    multiImport: "حدد الوسائط",
    filesTable: "الملفات المحفوظة",
    lastUpdate: "اخر تحديث",
    search: "يبحث",
    from: "من",
    labelRowsSelect: "الصفوف",
    labelRowsPerPage: "عدد الصفوف لكل صفحة",
    firstAriaLabel: "الصفحة الأولى",
    firstTooltip: "الصفحة الأولى",
    previousAriaLabel: "الصفحة السابقة",
    previousTooltip: "الصفحة السابقة",
    nextAriaLabel: "الصفحة التالية",
    nextTooltip: "الصفحة التالية",
    lastAriaLabel: "آخر صفحة",
    lastTooltip: "آخر صفحة",
    exportTitle: "احصل على ملف الإخراج",
    exportAriaLabel: "احصل على ملف الإخراج",
    exportCSVName: "احصل على إخراج CSV",
    emptyDataSourceMessage: "لا يوجد محتوى ليتم عرضه!",
    addTooltip: "يضيف",
    deleteTooltip: "يمسح",
    editTooltip: "تحرير سريع",
    filterTooltip: "منقي",
    deleteText: "هل أنت متأكد من حذف هذا الصف؟",
    cancelTooltip: "يلغي",
    saveTooltip: "يحفظ",
    rowsSelected: "صف محدد",
    uploadedDate: "تحميل الوقت",
    linkPlaceholder: "أدخل عنوان URL",
    number: "رقم",
    icon: "أيقونة",
    link: "وصلة",
    filters: "المرشحات",
    chooseColor: "اختر اللون",
    chooseFormat: "اختر التنسيق",
    editAdvertisement: "تحرير الإعلان",
    makeAdvertisement: "إنشاء إعلان",
    advertisementName: "عنوان الاعلان",
    advertisements: "الإعلانات",
    advertisement: "إعلان",
    advertisementsList: "قائمة الإعلانات",
    viewAdvertisement: "عرض الإعلان",
    updateTime: "تحديث",
    created_at: "تاريخ الإنشاء",
    updated_at: "تاريخ التحديث",
    gallery: "معرض الصور",
    requestToDeleteAd: "طلب حذف الإعلان",
    removeAd: "إزالة الإعلانات",
    requestedToDelete: "تم إرسال طلب إزالة الإعلان!",
    pageNotFound: "هذه الصفحة غير متوفرة!",
    contentAdded: "تم إنشاء رسالتك.",
    contentAddingFailed: "كانت هناك مشكلة في إنشاء المنشور.",
    contentEdited: "تم تحرير رسالتك.",
    contentEditingFailed: "كانت هناك مشكلة في تحرير المنشور.",
    deleteContent: "بعد حذف.",
    deleteContentFailed: "كانت هناك مشكلة في حذف المنشور.",
    firstConfirmShop: "تأكيد متجر هذا المستخدم أولاً!",
    confirmationStatusDescription: "حالة تأكيد المعلومات",
    confirmationStatusFilter: "حالة التأكيد",
    userConfirmationStatus: "حالة تأكيد معلومات المستخدم",
    confirmed: "مؤكد",
    unConfirmed: "بانتظار التأكيد",
    all: "الجميع",
    yourAdvertisementIsActive: "تهانينا! تم التحقق من إعلانك ونشط.",
    yourAdvertisementIsInchecking: "يتم فحص إعلانك!",
    price: "سعر",
    changeCategories: "تغيير الفئات",
    advertisementDescription: "شرح الإعلان الكامل",
    seoDescription: "شرح موجز",
    welcomeToPanel: "مرحبًا بك في لوحة مستخدم B2B Mineral!",
    agreement: "البنود و الظروف",
    pleaseConfirmTerms: "يرجى قراءة الشروط والأحكام والموافقة عليها",
    rulesConfirmation: "والموافقة عليه.",
    with: "أتفق مع",
    manageAdvertisements: "إدارة الإعلانات",
    myAdvertisements: "إعلاناتي",
    thumbnail: "صورة",
    creator: "الناشر",
    edit: "يحرر",
    view: "منظر",
    activity: "نشاط",
    details: "تفاصيل",
    deleteAdvertisement: "تم حذف الإعلان!",
    deleteAdvertisementFailed: "كانت هناك مشكلة في حذف الإعلان!",
    chooseCategory: "اختر الفئة",
    maxGallerySizeIs9: "الحد الأقصى لعدد صور المعرض هو 9!",
    manageComments: "إدارة التعليقات",
    manageCategories: "إدارة الفئات",
    usersManagement: "إدارةالمستخدم",
    allUsers: "جميع المستخدمين",
    usersActivity: "نشاط المستخدم",
    managePages: "إدارة صفحات",
    manageContact: "نموذج الاتصال",
    siteSettings: "إعدادات الموقع",
    siteInformation: "الإعدادات الرئيسية",
    manageSlideShows: "إدارة عرض الشرائح",
    manageBanners: "إدارة لافتات",
    editSlideShow: "تحرير عرض الشرائح",
    custom_css: "رمز CSS إضافي",
    custom_js: "رمز نص جافا إضافي",
    custom_head: "تمت إضافة رمز في موقع <head> على الويب",
    custom_body: "رمز إضافي في <body> للموقع",
    custom_code: "رمز إضافي",
    siteContactInformation: "معلومات الاتصال",
    moreInfo: "معلومات اكثر",
    makeCategory: "إنشاء فئة",
    editCategory: "تحرير الفئة",
    categoryName: "اسم التصنيف",
    categoryParentName: "القسم الرئيسي",
    categories: "فئات",
    pleaseChooseCategoriesFirst: "الرجاء اختيار فئة أولا!",
    category: "فئة",
    chooseSubCategory: "اختر الفئة الفرعية",
    chooseMainCategory: "اختر الفئة الرئيسية",
    removeImage: "إزالة الصورة",
    viewCategory: "عرض الفئة",
    username: "اسم المستخدم",
    userName: "اسم ولقب",
    confirmationStatus: "تأكيد المعلومات",
    registerDate: "تاريخ التسجيل",
    changeRole: "تغيير مستوى الوصول",
    role: "مستوى الوصول",
    allRoles: "جميع مستويات الوصول",
    exportUsersList: "تنزيل قائمة المستخدمين",
    addUser: "أضف المستخدم",
    editUser: "تحرير العضو",
    editDetails: "تعديل الملف الشخصي",
    dataEdited: "تم تحرير معلوماتك.",
    dataEditingFailed: "كان هناك مشكلة في تحرير البيانات.",
    deleteData: "تم حذف البيانات.",
    deleteDataFailed: "كانت هناك مشكلة في حذف هذه البيانات.",
    admin: "مسؤل",
    user: "مشتر",
    supplier: "تاجر",
    supportUser: "يدعم",
    product_manager: "مدير الإنتاج",
    support: "يدعم",
    adminType: "مسؤولون",
    normalType: "مستخدمون آخرون",
    usersLog: "أنشطة المستخدم",
    saveCode: "حفظ الرموز",
    writeYourCodehere: "اكتب رموزك هنا",
    customCodeTitle: "أضف الرموز التي تحتاجها!",
    customCodeText: "في هذا القسم ، يمكنك إضافة الرموز التي تحتاجها في أقسام مختلفة من موقع الويب الخاص بك وتخصيصها!",
    main_phone: "رقم هاتف الموقع",
    main_email: "البريد الإلكتروني الإلكتروني",
    main_mobile: "رقم الهاتف المحمول",
    main_address: "عنوان موقع ويب",
    facebook: "رابط الفيسبوك",
    linkedin: "رابط LinkedIn",
    pinterest: "رابط بينتيريست",
    instagram: "رابط Instagram",
    twitter: "رابط Twitter (X)",
    whatsapp: "رقم WhatsApp",
    telegram: "رابط Telegram",
    headerAndFooter: "إدارة الرأس والتذييل",
    short_description: "وصف قصير للموقع",
    footer_text: "وصف الوصف تذييل",
    footer_certificates: "رمز تذييل رمز",
    map_script: "رمز خريطة صفحة الاتصال",
    bg_image: "الصورة الخلفية",
    gradientBg: "خلفية التدرج",
    solidBg: "خلفية اللون الصلبة",
    imageBg: "خلفية مع الصورة",
    gradientBgFirstColor: "تدرج اللون الأول",
    gradientBgSecondColor: "لون التدرج الثاني",
    bg_type: "نوع صورة الخلفية",
    slideShowImages: "عناصر الصورة",
    SlideShowContent: "محتوى عرض الشرائح",
    nextStep: "الجزء التالي",
    mainImageSlide: "شريحة الصورة الرئيسية",
    contactInformation: "معلومات الاتصال على الموقع",
    footerCertificationTitle: "رمز الشهادات",
    footerCertificationText: "في هذا القسم ، يمكنك وضع الرموز المستلمة من منظمات مختلفة مثل رمز الثقة والوسائط الرقمية ، إلخ.",
    mapScriptTitle: "نص الخريطة",
    mapScriptText: "في هذا القسم ، يمكنك وضع الرموز المستلمة من منصات MAP مختلفة ، مثل خريطة Google ، التي سيتم عرضها على صفحة الاتصال.",
    saveChanges: "حفظ التغييرات",
    adminDashboard: "لوحة معلومات المشرف",
    userDashboard: "لوحة معلومات المستخدم",
    currencies: "العملات",
    addContactInformation: "أضف معلومات الاتصال",
    phoneNumber: "رقم التليفون",
    addPhoneNumber: "أضف رقم",
    addEmail: "أضف البريد الإلكتروني",
    relatedLinks: "روابط ذات علاقة",
    websiteLink: "رابط الموقع",
    facebookLink: "URL الفيسبوك",
    whatsappLink: "WhatsApp URL",
    telegramLink: "Telegram URL",
    instagramLink: "url url Instagram",
    twitterLink: "عنوان URL Twitter (X)",
    websitePlaceHolder: "https://yourwebsite.com",
    facebookPlaceHolder: "https://facebook.com/your_page",
    whatsappPlaceHolder: "+9712345678",
    telegramPlaceHolder: "https://t.me/your_number",
    instagramPlaceHolder: "https://instagram.com/your_page",
    twitterPlaceHolder: "https://twitter.com/your_page",
    phoneIsWrong: "رقم الهاتف خاطئ!",
    youtube: "موقع YouTube",
    addFromYoutube: "أضف مقطع فيديو من YouTube",
    addYoutubeLinkHere: "يرجى إدخال عنوان URL فيديو من YouTube في الحقل أدناه!",
    addVideo: "أضف الفيديو",
    youtubeLink: "url فيديو youtube",
    back: "خلف",
    wrongURlForYoutube: "عنوان URL خاطئ!",
    toggleDark: "الوضع المظلم",
    toggleLight: "وضع الضوء",
    addMainDetails: "التفاصيل الرئيسية",
    addImages: "إضافة الصور",
    primaryAdImage: "م الصورة الأساسية",
    savedFiles: "الملفات التي يتم تحميلها",
    fileSize20mb: "يجب أن يكون حجم الملف أقل من 20 ميجابايت!",
    fileSize100mb: "يجب أن يكون حجم الملف أقل من 100 ميجابايت!",
    addPreviewToVideo: "أضف معاينة إلى الفيديو",
    doYouWantToAddThumbnail: "هل تريد إضافة صورة مصغرة لهذا الفيديو؟",
    addPreviewImage: "حدد معاينة صورة",
    addVideoWithoutPreview: "أضف فيديو بدون معاينة",
    adsGallery: "معرض الإعلان",
    removeItem: "إزالة بند",
    addItem: "اضافة عنصر",
    address: "عنوان",
    addressAndLocation: "العنوان والموقع",
    country: "دولة",
    state: "ولاية",
    yourLocation: "موقعك",
    searchInMap: "ابحث في الخريطة",
    adAdded: "تم إنشاء إعلانك.",
    adAddingFailed: "كانت هناك مشكلة في إنشاء الإعلان.",
    adEdited: "تم تحرير إعلانك.",
    adEditingFailed: "كانت هناك مشكلة في تحرير الإعلان.",
    commentEdited: "تم تحرير التعليق",
    commentAdded: "تم إنشاء التعليق",
    commentAddingFailed: "كانت هناك مشكلة في إضافة تعليق.",
    commentEditingFailed: "كانت هناك مشكلة في تحرير التعليق.",
    commentRemoved: "تم إزالة التعليق بنجاح",
    rating: "تقييم",
    comment: "تعليق",
    comments: "تعليقات",
    editComment: "تعديل التعليق",
    addComment: "أضف تعليق",
    chooseAdForComment: "اختر إعلان للتعليق",
    noAds: "لم يتم العثور على إعلانات!",
    commentForReplayDescription: "اختر هنا إذا كنت تريد الرد على التعليق",
    parentCommentDesc: "أنت ترد على التعليق التالي:",
    site_logo: "شعار الموقع",
    favicon: "أيقونة الموقع (Favicon)",
    square_logo: "شعار المربع",
    page_header: "رأس الصفحات الداخلية",
    pagination_count: "مواد لكل صفحة",
    page_count: "عدد الصفحات التي يجب تحميلها مع Ajax",
    app_name: "اسم الموقع",
    youtubeChanel: "يوتيوب شانيل رابط",
    youtubePlaceHolder: "https://www.youtube.com/channel/your_chanel",
    viewPreview: "معاينة",
    userLikes: "يحب المستخدمون",
    adInfo: "معلومات م",
    viewAd: "عرض الإعلان",
    pleaseChooseYourLocation: "الرجاء اختيار موقعك!",
    fileInUse: "هذا الملف قيد الاستخدام!",
    backToHome: "خلف",
    basic_plan: "الخطة الأساسية",
    bronze_plan: "الخطة البرونزية",
    silver_plan: "خطة الفضة",
    gold_plan: "خطة الذهب",
    planInfo: "خطة معلومات",
    yourPlan: "خطتك",
    months: "شهور",
    adValidityPeriod: "فترة صحة الإعلان",
    monthlyAdCount: "عدد الإعلانات التي يمكن إنشاؤها",
    monthlyEditCount: "عدد التعديلات التي يمكن القيام بها",
    viewPricing: "عرض التسعير",
    youCantAddAd: "لا يمكنك إضافة إعلان جديد!",
    youCantEditAd: "لا يمكنك تعديل الإعلان!",
    planLimitationDescription: "وفقًا لخطتك ، لا يمكن القيام بذلك ، يرجى الاطلاع على التعريفات وإعداد خطتك المناسبة!",
    expired_at: "حتى ينتهي الإعلان",
    yourPlanDoesntSupportThis: "خطتك لا تدعم هذا",
    yourPlanDoesntSupportThisDesc: "ترقية خطتك لاستخدام هذه الميزة",
    manageFields: "إدارة الحقول",
    fieldName: "اسم الحقل",
    fieldArabicName: "الاسم العربي الميداني",
    fieldDescription: "حقل الوصف",
    fieldDescriptionArabic: "الوصف العربي الميداني",
    addField: "إضافة حقل",
    makeField: "إنشاء الحقل",
    editField: "تحرير الحقل",
    adminHasFullAccess: "المسؤول لديه وصول كامل!",
    pleaseAdCategoryFirst: "الرجاء تحديد فئة أولا!",
    specificFields: "حقول محددة",
    totalVisitsCount: "زيارات إجمالية",
    todayVisitsCount: "زيارات اليوم",
    totalCommentsCount: "تعليقات",
    noLogFound: "لم يتم العثور على نتائج",
    allowed_comments: "هل يُسمح بنشر التعليقات؟",
    allowed: "مسموح",
    not_allowed: "غير مسموح",
    expired: "منتهي الصلاحية",
    equalItem: "الإنجليزية متساوية",
    addEqual: "أضف عنصر متساوٍ",
    noEqual: "لا يوجد عنصر متساوٍ",
    emptyTableCells: "الجدول فارغ",
    AddTable: "إنشاء جدول",
    EditTable: "تحرير الجدول",
    TableBuilder: "منشئ الجدول",
    tableName: "اسم الطاولة",
    makeTable: "اصنع جدول",
    chooseTableStructure: "اختر أعمدة الجدول والصفوف",
    columns: "الأعمدة",
    rows: "الصفوف",
    addRows: "اضف سطر",
    addColumns: "إضافة العمود",
    buildTable: "بناء الجدول",
    withHeading: "الجدول مع العنوان",
    withoutHeading: "حكاية دون عنوان",
    tableTdPlaceholder: "اكتب المحتوى الخاص بك ...",
    shortcode: "رمز قصير",
    insertTable: "أدخل جدول",
    manageCategoriesOrder: "إدارة ترتيب الفئات",
    editSubMenuOrder: "ترتيب القائمة الفرعية",
    noCategoryFound: "فئة لم يتم العثور عليها!",
    youAreEditingParentCategoriesOrder: "أنت تقوم بتحرير فئات الوالدين!",
    youAreEditingCategoriesOrder: "فرز الفئات الفرعية لـ:",
    youAreEditingParentCategoriesOrderDesc: "اطلب الفئة الفرعية لكل فئة بالنقر فوق الزر تحرير.",
    update: "تحديث",
    updateAdReal: "تحديث الإعلان",
    updateAdRealDesc: "يمكنك تحديث إعلانك ليكون في وضع أعلى في قائمة الإعلانات!",
    advertisementUpdated: "إعلان محدث!",
    rate: "معدل",
    myBusiness: "أعمالي",
    businessAdded: "تم إنشاء عملك.",
    businessAddingFailed: "كانت هناك مشكلة في إضافة الأعمال.",
    businessEdited: "تم تحرير عملك.",
    businessEditingFailed: "كانت هناك مشكلة في تحرير العمل.",
    editBusiness: "تحرير الأعمال",
    makeBusiness: "إنشاء الأعمال",
    businessName: "الاسم التجاري",
    businesses: "الأعمال",
    business: "عمل",
    businessesList: "قائمة الشركات",
    viewBusiness: "عرض الأعمال",
    businessLogo: "شعار العمل",
    yourBusinessIsActive: "تهانينا! تم التحقق من عملك ونشط.",
    yourBusinessIsInchecking: "يتم فحص عملك!",
    businessBanners: "لافتات الأعمال",
    foundingYear: "سنة التأسيس",
    businessVideos: "أشرطة فيديو",
    manageBusinesses: "إدارة الأعمال",
    businessBio: "وصف موجز للأعمال",
    primaryBusinessImage: "الصورة الأساسية للأعمال",
    youCantAddBusiness: "غير قادر على إضافة الأعمال!",
    pageInfo: "معلومات الصفحة",
    remove: "يزيل",
    deleteIt: "يمسح",
    active: "نشيط",
    activeStatus: "حالة",
    addSlideShow: "أضف عرض الشرائح",
    chooseAdForSlide: "اختر إعلان للشرائح",
    banner_image: "صورة بانر",
    addBanner: "أضف لافتة",
    editBanner: "تحرير لافتة",
    pageTitle: "عنوان الصفحة",
    addPage: "إضافة صفحة",
    makePage: "إنشاء صفحة",
    editPage: "تعديل الصفحة",
    viewPage: "عرض الصفحة",
    youCantDeletePermanentPages: "لا يمكن حذف الصفحات الدائمة!",
    manageFAQ: "أسئلة مكررة",
    addFAQ: "إضافة الأسئلة الشائعة",
    editFAQ: "تحرير الأسئلة الشائعة",
    question: "سؤال",
    answer: "إجابة",
    editDiscount: "تحرير الخصم",
    makeDiscount: "جعل الخصم",
    discountName: "عنوان الخصم",
    discounts: "خصومات",
    discount: "تخفيض",
    discountsList: "قائمة الخصومات",
    viewDiscount: "عرض الخصم",
    manageDiscounts: "إدارة الخصومات",
    myDiscounts: "خصومات بلدي",
    discountAdded: "تم إنشاء خصمك.",
    discountAddingFailed: "كانت هناك مشكلة في إنشاء الخصم.",
    discountEdited: "تم تحرير الخصم الخاص بك.",
    discountEditingFailed: "كانت هناك مشكلة في تحرير الخصم.",
    deleteDiscount: "تم حذف الخصم!",
    deleteDiscountFailed: "كانت هناك مشكلة في حذف الخصم!",
    discountInfo: "معلومات الخصم",
    primaryDiscountImage: "صورة الخصم الأولية",
    discountGallery: "معرض الصور",
    discountDescription: "معلومات الخصم",
    requestToDeleteDiscount: "طلب حذف الخصم",
    removeDiscount: "إزالة الخصم",
    choose: "يختار",
    discount_percent: "نسبة الخصم",
    priceDiscount: "الكمية الفعلية",
    discount_price: "المبلغ بما في ذلك الخصم",
    inventory: "جرد",
    discount_expire_time: "خصم وقت انتهاء الصلاحية",
    dateShouldBeAfterToday: "الوقت خاطئ!",
    discount_expired_at: "حتى ينتهي الخصم",
    manageDiscountsSlider: "إدارة منزلق الخصومات",
    bannerTitle: "لافتة لافتة",
    wrongPercent: "نسبة خاطئة",
    discount_banner_1: "لافتة صفحة خصم واحدة 1",
    discount_banner_2: "لافتة صفحة خصم واحدة 2",
    bannerPosition: "موقف لافتة",
    sliderTitle: "عنوان الشريحة",
    primary_button_text: "نص الزر الأساسي",
    primary_button_icon: "أيقونة الزر الأساسي",
    primary_button_link: "رابط الزر الأساسي",
    manageAdsComments: "عرض الإعلانات",
    manageBusinessesComments: "عرض الشركات",
    manageDiscountsComments: "عرض الخصومات",
    ad: "إعلان",
    chooseItemForComment: "اختر عنصر للتعليق",
    noItem: "لم يتم العثور على عنصر!",
    term_of_use: "البنود و الظروف",
    articles: "مقالات",
    manageContents: "إدارة المقالات",
    contentsList: "قائمة المقالات",
    makeContent: "إنشاء مقال",
    manageBlogCategories: "فئات",
    manageBlogComments: "إدارة التعليقات",
    contents: "النصوص",
    addContent: "إنشاء منشور جديد",
    viewContent: "عرض المشاركات السابقة",
    editContent: "تعديل المنشور",
    contentTitle: "عنوان المقال",
    published: "نشرت",
    draft: "مسودة",
    publishStatus: "النشر الحالة",
    viewArticle: "عرض المقال",
    relatedAds: "الإعلانات ذات الصلة",
    chooseAd: "اختر الإعلان",
    addAd: "أضف م",
    tickets: "بطاقة الدعم",
    manageMessages: "إدارة الرسائل",
    manageTickets: "إدارة التذاكر",
    manageDepartments: "إدارة الإدارات",
    requestType: "نوع الطلب",
    supportRequestFromDepartment: "دعم النظام",
    supportRequestForProduct: "دعم المنتج",
    sendFile: "إرسال ملف",
    ticket_sender: "مرسل",
    ticket_receiver: "متلقي",
    ticket_title: "عنوان",
    ticket_status: "حالة",
    ticket_edited_at: "آخر ما نشر",
    sendMessage: "أرسل رسالة",
    backToTickets: "العودة إلى التذاكر",
    deleteTicket: "تم حذف التذاكر!",
    deleteTicketFailed: "فشل في حذف التذكرة!",
    ticket_details: "تفاصيل التذكرة",
    ticket_sent: "إرسال التذكرة!",
    sendFastTicket: "إرسال تذكرة سريعة",
    sendMessageTo: "أرسل رسالة إلى",
    addTicket: "إرسال التذكرة",
    send: "يرسل",
    messageText: "رسالة نصية",
    gotToTicketPage: "صفحة الرسائل",
    removeRequest: "إزالة الطلب",
    related_admin: "المسؤول ذات الصلة",
    department: "قسم",
    departments: "الإدارات",
    low: "قليل",
    medium: "واسطة",
    high: "عالي",
    subject: "موضوع",
    message: "رسالة",
    priority: "أولوية",
    unread: "في انتظار المراجعة",
    read: "يقرأ",
    sentTicket: "مرسل",
    sent: "مرسل",
    referToMe: "أشار إلي",
    resumeOrder: "متابعة الطلب",
    bill: "فاتورة",
    checkout: "إكمال الطلب",
    totalPrice: "السعر الكلي",
    productsInCart: "المنتجات في العربة",
    totalCartPrice: "مجموع العربة",
    totalUserDiscounts: "إجمالي الخصومات",
    totalDiscounts: "الربح الخاص بك",
    makeOrder: "اصنع ترتيبًا",
    orderPayed: "يتم دفع الطلب.",
    orderPayingFailed: "هناك مشكلة في دفع المبلغ!",
    pending: "انتظر القبول",
    not_paid: "في انتظار الدفع",
    viewOrders: "عرض الطلبات",
    viewOrdersDetails: "عرض جميع أوامرك السابقة من هذا القسم!",
    viewOrder: "مشاهدة الطلب",
    orders: "المشتريات",
    ordersList: "قائمة الأوامر",
    orderDetails: "تفاصيل الطلب",
    productsList: "قائمة الخصومات",
    purchase: "قسط",
    payableMoney: "المبلغ المستحق",
    payOrder: "ترتيب الأجور",
    order_status: "حالة الطلب",
    factor_number: "رقم الفاتورة",
    factor_status: "حالة الفاتورة",
    created_at_factor: "تاريخ الطلب",
    mobileNumber: "رقم الهاتف المحمول",
    status: "حالة",
    payed: "دفعت",
    not_used: "غير مستعمل",
    used: "مستخدم",
    unique_key: "رمز فريد",
    not_use: "غير مستعمل",
    withoutExpire: "دون انتهاء الصلاحية",
    manageOrders: "إدارة الطلبات",
    dataRemoved: "تمت إزالة البيانات بنجاح",
    dataRemovingFailed: "كانت هناك مشكلة في إزالة البيانات.",
    buyer: "مشتر",
    doesntHave: "ليس لديه",
    dateShouldBeAfterExpire: "وقت انتهاء الصلاحية خاطئ!",
    expire_after_purchase: "انتهاء الصلاحية",
    businessContactInfo: "معلومات الاتصال",
    manageBusinessTypes: "إدارة أنواع الأعمال",
    uniqueKey: "مفتاح فريد",
    business_type_name: "أكتب اسم",
    business_arabic_type_name: "اسم النوع العربي",
    business_type: "نوع العمل",
    isThisFieldRequired: "هذه الخانة مطلوبه!",
    field_type: "نوع الحقل",
    text: "نص",
    long_text: "نص طويل",
    select_field: "حدد المربع",
    multi_select_field: "Multi Select Box",
    options: "خيارات اللغة الإنجليزية",
    arabic_options: "الخيارات العربية",
    pressEnterToMakeEachOption: "اضغط على Enter لإنشاء كل خيار",
    businessFields: "مجالات الأعمال",
    productFields: "حقول المنتجات",
    faqFields: "حقول طلب عرض الأسعار",
    pleaseChooseABusinessTypeForFields: "حدد نوع العمل لتحديد الحقول المتعلقة به. وإلا ، يمكنك رؤية الحقول العالمية.",
    globalFields: "المجالات العالمية",
    fields: "مجالات",
    total_employees: "عدد الموظفي",
    total_annual_revenue: "إجمالي الإيرادات السنوية",
    position_type: "موقف الميدان",
    trade_capacity: "القدرة التجارية",
    product_capacity: "قدرة المنتج",
    units: "الوحدات الانجليزية",
    arabic_units: "الوحدات العربية",
    pleasChooseBusinessTypeFirst: "الرجاء اختيار نوع العمل أولاً.",
    contactPersonAvatar: "اتصال الشخص",
    contact_person_name: "اسم شخص الاتصال",
    contact_person_position: "اتصال شخص الاتصال",
    certificates: "الشهادات",
    addCertificate: "إضافة شهادة",
    editCertificate: "شهادة تحرير",
    certificate: "شهادة",
    working_days_times: "أيام العمل والأوقات",
    businessDescription: "وصف العمل",
    businessTypes: "أنواع الأعمال" ,
    makeProduct: "إنشاء منتج",
    manageProducts: "إدارة المنتجات" ,
    product: "المنتج" ,
    shortName: "الاسم المختصر",
    iconImage: "صورة الرمز" ,
    productAdded: "تم إنشاء منتجك.",
    productAddingFailed: "كانت هناك مشكلة في إنشاء المنتج.",
    productEdited: "تم تحرير منتجك.",
    productEditingFailed: "كانت هناك مشكلة في تحرير المنتج.",
    deleteProduct: "تم حذف المنتج!",
    deleteProductFailed: "كانت هناك مشكلة في حذف المنتج!",
    youCantAddProduct: "لا يمكنك إضافة منتج جديد!",
    yourProductIsActive: "تهانينا! تم التحقق من منتجك ونشط.",
    yourProductIsInchecking: "يتم فحص منتجك!",
    viewProduct: "عرض المنتج",
    availableProducts: "المنتجات المتاحة",
    productName: "اسم المنتج",
    moq: "مو",
    moqDesc: "أقل كمية ممكن طلبها",
    payment_method: "طريقة الدفع او السداد",
    primaryProductImage: "صورة المنتج",
    productGallery: "معرض المنتج",
    company_address: "عنوان الشركة",
    productDescription: "وصف المنتج",
    openControl: "عرض الضوابط",
    originCountry: "بلد المنشأ",
    attachments: "المرفقات",
    addFile: "اضف ملف",
    companyAddress: "عنوان الشركة",
    depotAddress: "عنوان المستودع",
    hasDepotLocation: "مستودع هذا المنتج في مكان آخر",
    hasNoDepotLocation: "مستودع هذا المنتج في نفس موقع الشركة",
    removeProduct: "إزالة المنتج",
    requestToDeleteProduct: "طلب حذف المنتج",
    pleaseChooseACategoryForFields: "حدد الفئة لتحديد الحقول المتعلقة بها. وإلا ، يمكنك رؤية الحقول العالمية.",
    packaging_delivery: "التغليف والتسليم",
    essential_details: "التفاصيل الأساسية",
    supply_ability: "قدرة العرض",
    product_details: "تفاصيل المنتج",
    pleasChooseCategoryFirst: "الرجاء اختيار الفئة الأولى.",
    priceIsLessThanDiscount: "السعر أقل من سعر الخصم",
    editProduct: "تحرير المنتج",
    productInfo: "معلومات المنتج",
    manageRFQs: "إدارة RFQs",
    myRFQs: "RFQs بلدي",
    wanted: "مطلوب",
    destination: "وجهة",
    rfqList: "قائمة RFQS",
    rfqs: "RFQs",
    rfq: "RFQ",
    rfqAdded: "تم إنشاء RFQ الخاص بك.",
    rfqAddingFailed: "كانت هناك مشكلة في إنشاء RFQ.",
    rfqEdited: "تم تحرير RFQ الخاص بك.",
    rfqEditingFailed: "كانت هناك مشكلة في تحرير RFQ.",
    deleteRFQ: "تم حذف RFQ!",
    deleteRFQFailed: "كانت هناك مشكلة في حذف RFQ!",
    yourRFQIsActive: "Congratulations! Your RFQ is verified and active.",
    yourRFQIsInchecking: "يتم فحص RFQ الخاص بك!",
    viewRFQ: "عرض RFQ",
    rfqInfo: "معلومات RFQ",
    makeRFQ: "إنشاء RFQ",
    editRFQ: "تحرير RFQ",
    rfqName: "البحث عن",
    rfqNameDesc: "ما المنتج الذي تريده؟",
    destinationCountry: "وجهة",
    buyerFrom: "المشتري من",
    quantityRequirement: "متطلبات الكمية",
    payment_terms: "شروط الدفع",
    shipping_terms: "شروط الشحن",
    rfqDescription: "وصف RFQ",
    requestToDeleteRFQ: "طلب حذف RFQ",
    removeRFQ: "إزالة RFQ",
    RFQFields: "حقول RFQ",
    rfq_custom_fields: "حقول RFQ المخصصة",
    youCantAddRFQ: "لا يمكنك إضافة RFQ جديد!",
    availableRFQs: "RFQs المتاحة",
    leadtimeImage: "صورة المهلة",
    productExamples: "أمثلة المنتج",
    average_rating: "المعدل المتوسط",
    product_side_banner: "لافتة جانب المنتج",
    simple_user: "مستخدم",
    removeBusiness: "إزالة الأعمال",
    ShowCaseProducts: "عرض المنتجات",
    main_products: "المنتجات الرئيسية",
    other_products: "منتجات اخرى",
    addToMain: "أضف إلى المنتجات الرئيسية",
    main_product: "المنتج الرئيسي",
    noProductFound: "لم يتم العثور على منتج!",
    bannerSize: "يجب أن يكون حجم اللافتة 1200 × 300 بكسل",
    business_home_banner: "شعار الصفحة الرئيسية للأعمال",
    products: "منتجات",
    businessGallery: "معرض الأعمال",
    gallerySize: "يجب ألا يقل عرض الصورة عن 600 بكسل",
    mainProducts: "المنتجات الرئيسية",
    rfq_top_banner: "RFQ أعلى لافتة",
    rfq_expired_at: "حتى انتهاء صلاحية طلب عرض الأسعار",
    rfq_expire_time: "وقت انتهاء صلاحية RFQ",
    homeTopSlideShow: "الصفحة الرئيسية أعلى عرض الشرائح",
    homeMiddleSlideShow: "الصفحة الرئيسية عرض الشرائح الأوسط",
    home_middle_slider_delay: "الصفحة الرئيسية تأخير عرض الشرائح الوسطى",
    home_top_slider_delay: "الصفحة الرئيسية تأخير عرض الشرائح",
    changeDelayTime: "تغيير وقت التأخير",
    home_left_banner: "اليسار راية المنزل",
    registerHint: "أخبرنا قليلاً عن عملك لكي نتواصل \n مع ملايين المشترين والموردين.",
    messages: "رسائل",
    changeMobileOrEmail: "تغيير رقم الهاتف المحمول أو البريد الإلكتروني",
    changeMobileOrEmailDesc:"إذا تغيرت المعلومات ، يلزم إعادة التأكيد.",
    anotherUserHasThisInfo:"تم بالفعل تسجيل المعلومات المدخلة من قبل مستخدم آخر.",
    chooseOwnerFile:"صاحب الملف",
    isThisProductPopular: "هل هذا المنتج شائع؟",
    imageProperWidthHeight: "العرض المناسب والارتفاع",
    imageProperSize: "الحد الأقصى لحجم الصورة",
    videoProperSize: "الحد الأقصى لحجم الفيديو",
    ok: "نعم",
    per: "لكل",
    monday: "الاثنين",
    tuesday: "يوم الثلاثاء",
    wednesday: "الأربعاء",
    thursday: "يوم الخميس",
    friday: "جمعة",
    saturday: "السبت",
    sunday: "الأحد",
    off: "مغلق",
    start_time: "وقت البدء",
    end_time: "وقت النهاية",
    showcase_products: "معرض المنتجات",
    duplicateProductName: "لقد قمت بالفعل بإنشاء منتج بهذا الاسم.",
    relatedUser: "المستخدم ذي الصلة",
    chooseAUser: "اختر مستخدمًا!",
    relatedUserBusiness: "مستخدم / عمل ذي صلة",
    YouCantAddProductBecauseOfBusiness: "لإنشاء منتج ، يرجى إنشاء صفحة عملك أولاً.",
    inquiryRequests: "طلبات الاستفسار",
    contact: "اتصال",
    rfqsMessages: "رسائل RFQS",
    productsMessages: "رسائل المنتجات",
    businessesMessages: "رسائل الأعمال",
    sample_request: "عينة ل",
    sampleRequests: "عينة طلبات",
    provider: "مزود",
    isThisProductVerifiedSample: "هل تم التحقق من عينة المنتج هذه؟",
    sample: "طلب عينة",
    system: "تذكرة النظام",
    isThisFieldInCompare: "يظهر في صفحة المقارنة",
    isThisBannerActive: "هل هذا الشعار نشط؟",
    blog: "مدونة",
    active_people: "قصص ساخنة",
    simple: "Simple",
    articlesGallery: "معرض المقالات",
    makeBlogCategory: "إنشاء فئة",
    editBlogCategory: "تحرير الفئة",
    equalCategory: "الإنجليزية متساوية",
    is_featured_article: "هل هذا المنشور بين أفضل المشاركات؟",
    bannerSizeContent: "حجم الصورة 1300x600px",
    newsletterSubscribers: "مشتركين",
    pageCover: "غطاء الصفحة",
    blog_bottom_banner: "لافتة أسفل المدونة",
    description: "وصف",
    title: "عنوان",
    about_page_banner_1: "حول بطاقة الصفحة 1",
    about_page_banner_2: "حول بطاقة الصفحة 2",
    about_page_banner_3: "حول بطاقة الصفحة 3",
    about_page_banner_4: "حول بطاقة الصفحة 4",
    main_fax: "رقم فاكس الموقع",
    FAQCategory: "فئة",
    faq_categories: "فئات الأسئلة الشائعة",
    help_items: "مساعدة العناصر",
    pleaseChooseAFile: "الرجاء اختيار ملف",
    yourInfoIsUnderChecking: "تتم مراجعة معلوماتك",
    yourInfoIsUnderCheckingDesc: "بعد التحقق من خبراء الموقع ، سيتم إبلاغك بنتيجة التسجيل",
    confirmRegisterInfo: "تأكيد معلومات التسجيل",
    rejectRegisterInfo: "المعلومات المرفوضة",
    areInfosConfirmed: "تم تأكيد معلومات تسجيل المستخدم",
    faults: "أخطاء نموذج التسجيل",
    registerInfo: "تسجيل المعلومات",
    registerInfoNeedApprovalTitle: "تحقق من معلومات التسجيل",
    registerInfoNeedApproval: "يجب التحقق من معلومات تسجيل المستخدم ، يرجى التحقق من العناصر والموافقة عليها أو رفضها",
    yourInfoIsVerified: "لقد تم التحقق منك بنجاح",
    yourInfoIsVerifiedDesc: "تم التحقق من معلوماتك وتم تعيين خطة Lite.",
    yourInfoIsChecked: "تم فحص معلومات التسجيل الخاصة بك",
    pleaseReadAndDo: "يرجى قراءة ما يلي وتحرير معلوماتك",
    editInfo: "تحرير المعلومات",
    managePlanParents: "مجموعات خطط",
    plans: "خطط",
    makePlanParent: "إنشاء خطة الوالدين",
    planName: "اسم خطة",
    planArabicName: "اسم الخطة العربية",
    arabic_description: "الوصف العربي",
    editParent: "تحرير مجموعة خطة",
    makeParent: "إنشاء مجموعة خطة",
    planFeatures: "ميزات خطة",
    planArabicFeatures: "خطة السمات العربية",
    managePlans: "إدارة الخطط",
    days: "يوم",
    editPlan: "تحرير الخطة",
    makePlan: "إنشاء خطة",
    planParent: "مجموعة خطة",
    discountPrice: "سعر مخفض",
    durationPlan: "مدة الصلاحية",
    planValidity: "صالح حتى",
    viewPlans: "عرض الخطط",
    myPlan: "خطتي",
    reserved_plan: "خطة محفوظة",
    planDuration: "مدة الخطة",
    forPurchasingBetterPlansAdsAndReserveGoToPlans: "يمكنك زيارة صفحة خطط لترقية خطتك",
    plansPage: "عرض الخطط",
    userPlanInfo: "معلومات خطة المستخدم",
    givePlanToUser: "أعط الخطة للمستخدم",
    choosePlan: "اختر الخطة",
    plan: "يخطط",
    changeColor: "غير اللون",
    color: "لون",
    make_products: "عدد المنتجات لإنشاء",
    answer_to_rfq: "عدد الإجابات الأسبوعية على RFQs",
    verify_badge: "تحقق من الشارة",
    has_products_top_list: "فئات المنتجات العليا",
    show_in_popular: "عرض المنتجات على المنتجات الشعبية",
    has_commercial_banner: "لافتة تجارية في أجزاء مختلفة من الموقع",
    has_banner_design: "تصميم لافتة الحصري لصفحة الأعمال",
    has_business_page: "صفحة العمل مع ميزات خاصة",
    has_products_showcase: "عرض المنتجات",
    has_business_catalogue: "تحميل كتالوج الشركة",
    has_business_video: "تحميل فيديو الشركة",
    has_business_image: "تحميل صورة الشركة",
    has_certificates: "تحميل شهادات الشركة",
    has_send_rfq: "إمكانية نشر RFQ",
    has_receive_public_rfq: "تلقي RFQ العام",
    has_receive_private_rfq: "تلقي RFQ الخاص",
    has_backlink: "رابط للمشترين الذين تم التحقق منها",
    has_social_media_marketing: "تسويق وسائل الاعلام الاجتماعية",
    has_support: "24/7 الدعم والدردشة",
    has_youtube: "تحميل الفيديو من يوتيوب",
    step1: "التسجيل الأولي",
    step2: "آكمل الطلب",
    step3: "تحميل المستندات",
    step4: "انتظر القبول",
    step5: "يتطلب التعديل",
    step6: "مؤكد",
    license_number: "رقم الرخصة",
    license_expiration_date: "تاريخ انتهاء صلاحية الترخيص",
    withRegisteringAnAccount: "من خلال إنشاء حساب ، أنت تتفق مع",
    termsAndConditions: "الأحكام والشروط",
    and: "و",
    saveAndContinue: "حفظ ومتابعة",
    companyName: "اسم الشركة",
    uploadAtLeast3items: "يرجى تحديد وتحميل 3 مستندات على الأقل من القائمة أدناه",
    documentName: "اسم الملف",
    dateAdded: "تم إضافة التاريخ",
    verified: "تم التحقق",
    companyDocuments: "وثائق الشركة",
    documentCategory: "فئة الوثيقة",
    uploadDocument: "وثيقة تحميل",
    chooseFile: "اختر ملف",
    upload: "رفع",
    duplicated_document: "الرجاء تحديد مستندات مختلفة",
    congratulation: "تهنئة",
    buyPlan: "شراء الخطة",
    register_completed_text: "تسجيل النص المكتمل",
    month: "شهور",
    not_payed: "في انتظار الدفع",
    myTransactions: "المعاملات",
    paymentManagement: "إدارة الدفع",
    amount: "كمية",
    transactionType: "نوع المعاملة",
    withdrawMoney: "طلب الإيداع",
    withdrawMoneyDescription: "أدخل المبلغ الذي تريد إيداعه.",
    deposit: "تكلفة",
    withdraw: "انسحاب",
    manageWallets: "إدارة المعاملات",
    manageWallet: "إدارة المعاملات",
    balance: "توازن",
    manageUserWallet: "إدارة معاملات المستخدم",
    totalResponses: "إجمالي الاستجابات",
    viewTicket: "عرض التذكرة",
    productsMyList: "قائمة منتجاتي",
    rfqMyList: "قائمة RFQS الخاصة بي",
    isThisProductHasSample: "هل لديك عينة من هذا المنتج لتوفيرها للعميل؟",
    ticket: "تذكرة",
    ticketType: "نوع التذكرة",
    chooseARFQ: "اختر RFQ",
    infinite: "لانهائي",
    businessCatalog: "كتالوج الأعمال",
    becomePremium: "تصبح قسط",
    staringDate: "تاريخ البدء",
    viewRegisterInfo: "عرض معلومات التسجيل",
    viewRegisterInfoHint: "يمكنك رؤية معلومات التسجيل الخاصة بك في هذا القسم. إذا كنت بحاجة إلى تغيير المعلومات ، فأرسل تذكرة.",
    terminateRequest: "طلب إنهاء العضوية",
    sellerGuidLine: "المبدأ التوجيهي",
    submitCompanyInfo: "إرسال معلومات الشركة",
    letsGo: "دعنا نذهب",
    enjoyPremiumFeatures: "استمتع بميزات متميزة",
    createRFQ: "إنشاء RFQ",
    createProduct: "إنشاء المنتج",
    myProducts: "منتجاتي",
    upgradeYourPlan: "ترقية خطتك",
    companyPage: "صفحة الشركة",
    createBusinessPageToSee: "قم بإنشاء صفحة عمل لرؤية المعلومات هنا",
    upgradeYourPlanToCreateProducts: "ترقية خطتك لإنشاء منتجات",
    numberOfProducts: "عدد من المنتجات",
    numberOfRFQs: "عدد RFQs",
    viewList: "عرض القائمة",
    totalAnswersToYourRFQs: "إجابات على RFQs",
    visitsChart: "زيارات الرسم البياني",
    liveUsers: "المستخدمون المباشرون",
    newProducts: "اليوم منتجات جديدة",
    todayRFQs: "اليوم RFQs جديدة",
    newRegistrations: "اليوم مستخدمين جدد",
    todayAllUnRegisteredUsers: "اليوم جميع الزوار",
    invalidEmail: "هذا البريد الإلكتروني غير موجود.",
    recoverByEmail: "استرداد مع عنوان البريد الإلكتروني",
    recoverByMobile: "استرداد مع رقم الهاتف المحمول",
    recoverPasswordTextEmail: "أدخل عنوان بريدك الإلكتروني لاستعادة كلمة المرور الخاصة بك.",
    receiver:"المتلقي",
    ifYouWantToSeeMoreInfoClick: "إذا كنت تريد أن ترى المعلومات الكاملة لهذه الصفحة",
    clickHere: "انقر هنا",
    attentionText: "يرجى ملاحظة أنه إذا قمت بالنقر فوق، فسوف تدخل إلى اللوحة العربية",
    expirationDate: "انتهاء الصلاحية",
    youAreViewingUserRegisterInfo: "عزيزي المشرف، أنت تقوم بعرض معلومات تسجيل المستخدم",
    registerInfoDone: "يمكنك تحرير معلومات تسجيل المستخدم في هذا القسم",
    editDetailss: "عدل التفاصيل",
    youCanEditUserRegisterInfo: "لعرض وتحرير معلومات تسجيل المستخدم استخدم هذا الزر",
    ordersListAdmin: "قائمة الطلبات",
    userType: "نوع المستخدم",
    verifiedUser: "مستخدم تم التحقق منه",
    verifiedUsers: "المستخدمون الذين تم التحقق منهم",
    normalUser: "مستخدم عادي",
    completingForm: "استكمال",
    verified_sample_text: "تم التحقق من عينة النص",
    productGallerySize: "يجب أن يكون حجم الصورة 800x600 بكسل",
    weakPassword: "كلمة المرور التي تم إدخالها ضعيفة",
    noMatchPassword: "التأكيد ليس مثل كلمة المرور",
    monthlySales: "هذا الشهر",
    totalSale: "المجموع",
    plansSales: "أوامر الخطة",
    users: "المستخدمين",
    pageCoverSize: "أفضل حجم هو 1550 بكسل × 300 بكسل (الأحجام الأخرى مناسبة)",
    fileSize: "مقاس",
    userSuspensionStatus: "تعليق المستخدم",
    userSuspensionStatusDescription: "من هذا القسم، يمكنك ضبط حالة تسجيل المستخدم وخطة تعليقه",
    suspended: "معلق",
    yourAccountIsSuspended: "تم تعليق حسابك",
    linkedinLink: "لينكد إن لينك",
    linkedinPlaceHolder: "https://linkedin.com/your_page",
    changeCover: "تغيير المعاينة",
    keywordsLabel: "الكلمات الدالة",
    allKeywordsAreTyped: "لا يمكنك إضافة المزيد من الكلمات الرئيسية",
    keywordsDescription: "اكتب العلامات ذات الصلة",
    pressEnterAfterEachKeyword: "اضغط على زر Enter بعد كتابة كل كلمة رئيسية",
    deleteBusiness: "Business deleted!",
    deleteBusinessFailed: "There was a problem deleting the business!",
    deleteDataFailedPlanParent: "Please delete the plans of this group first.",
    userCurrentStep: "Current step",
    free: "Free",
    confirm_payment: "Payment confirmation",
    transactionTrackingCode: "Transaction Tracking Code",
    step2Text: "Complete the form: If you want to have a business and product page",
    step2Text2: "Complete the form: If you want to be a verified buyer",
    rfqGuidelineText: "Create your buy requirement for free.",
    reserveSituation: "How to activate new plan",
    do_reserve: "Reserve plan until the end of the current plan",
    do_renew: "Replaces the current plan now",
    manageBusinessTypesOrder: "Manage the order of Business types",
    meta_title : "Meta Title" ,
    makePageNoIndex : "Do not display to search engines (No Index)" ,
    seoDetails : "Manage SEO" ,
};