import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../../components/editor/RichEditor";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import SubTitle from "../../../components/elements/SubTitle";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import AllInboxRoundedIcon from '@material-ui/icons/AllInboxRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Button from "@material-ui/core/Button/Button";
import moment from "moment-timezone";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import {makeSlug, translate} from "../../../../config/lang";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import SingleImageChooser from "../../fileManager/Components/SingleImageChooser";
import SlideShowsManager from "../settings/slideshows/SlideShowsManager";
import {getTimeZone} from "../../../../config/values";
import MakeEqualPage from "./MakeEqualPage";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

class MakePage extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            description:"",
            meta_title:"",
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        no_index: 0,

        thumbnail: null,
        errors:{},
        loading: true,
        no_editor: false,
        SubmitLoading: false,
        publish: true,
        id:this.props.match.params.id,
        editMode:false,
        updated_at:null,
        created_at:null,
        slug: null,
        equal: null,
        item: null,
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            })
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    handleChangePublish(){
        this.setState({
            publish: !this.state.publish,
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/page/getById/${this.state.id}`, config)
                .then(response => {
                    if(response.data.lang !== this.props.locale){
                        if(!!response.data.equal_id){
                            this.setState({
                                loading:true
                            })
                            window.location.replace(`${defaultVariabls.React}/edit/page/${response.data.equal_id}`);
                        }
                    }
                    this.setState({
                        fields: {
                            name: response.data.name,
                            description: !!response.data.description ? response.data.description : '',
                            meta_title: !!response.data.meta_title ? response.data.meta_title : '',

                        },
                        thumbnail: response.data.thumbnail,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                        updated_at:response.data.updated_at,
                        created_at:response.data.created_at,
                        slug: response.data.slug,
                        no_editor: response.data.no_editor,
                        equal: response.data.equal,
                        item: response.data,
                        no_index: response.data.no_index,

                    })
                    this.setState({
                        loading: false
                    })
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = translate('maxLength150');
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }

    handleRequest(){
        let { fields,thumbnail,content,id,editMode,no_index} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('lang', this.props.locale);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('meta_title', fields.meta_title);
        data.append('no_index', !!no_index ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/page/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('contentEdited') : translate('contentAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.props.history.push(`/manage/pages`);

                if(!this.state.editMode){

                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('contentEditingFailed') : translate('contentAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }

    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    render(){
        let {fields, errors,SubmitLoading,no_index,thumbnail,content,editMode,slug,equal,item} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editPage') : translate('makePage')}>
            {
                <Grid container spacing={2} justify="center">

                    <Grid item xs={12} lg={11}>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={8} lg={9}>
                                {
                                    !!editMode &&
                                    <>
                                        {
                                            this.state.loading ? null :
                                                <div style={{marginBottom:15}}>
                                                    <AppCard
                                                        className={this.classes.equalAppCard}
                                                    >
                                                        <div
                                                            className={this.classes.equalHolder}
                                                        >
                                                            <SubTitle noHR align="left">{translate('equalItem')}</SubTitle>
                                                            <MakeEqualPage
                                                                equal_id={!!equal ? equal.id : null}
                                                                item={item}
                                                                reload={() => this.loadData()}
                                                            />
                                                        </div>
                                                    </AppCard>
                                                </div>
                                        }
                                    </>
                                }
                                <AppCard
                                    title={editMode ? translate('editPage') : translate('makePage')}
                                    action={
                                        <IconButton><AllInboxRoundedIcon color="secondary"/></IconButton>
                                    }>
                                    {
                                        this.state.loading ? <AppCardLoaderFields/> :
                                            <Grid container spacing={2} justify="center" alignItems="center">

                                                <Grid item xs={12}>
                                                    <TextField
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        variant="outlined"
                                                        name="name"
                                                        label={translate('pageTitle')}
                                                        type="text"
                                                        size="small"
                                                        value={fields.name}
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['name']}
                                                        helperText={errors['name']}
                                                    />
                                                </Grid>
                                                {
                                                    !this.state.no_editor  &&
                                                    <Grid item xs={12}>
                                                        <RichEditor ref={this.EditorChild} Output={this.handleOutPut.bind(this)} input={content.RAW}/>
                                                    </Grid>
                                                }

                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        multiline
                                                        fullWidth
                                                        name="description"
                                                        label={translate('seoDescription')}
                                                        placeholder={translate('seoDescription')}
                                                        type="text"
                                                        id="description"
                                                        rows="4"
                                                        value={fields.description}
                                                        onChange={this.handleChange.bind(this)}
                                                        onClick={this.handleClickDescription.bind(this)}
                                                        error = {!!errors['description']}
                                                        helperText={errors['description']}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={this.state.no_index} onChange={() => this.setState({no_index:!this.state.no_index})} />}
                                                            label={translate('makePageNoIndex')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        name="meta_title"
                                                        variant="outlined"
                                                        label={translate('meta_title')}
                                                        value={fields.meta_title}
                                                        onChange={this.handleChange.bind(this)}
                                                        error={!!errors['meta_title']}
                                                        helperText={errors['meta_title']}
                                                    />
                                                </Grid>
                                                <Hidden smDown>
                                                <Grid item xs={12}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        onClick = {this.handleSubmit.bind(this)}
                                                        loading = {SubmitLoading}
                                                    >
                                                        {editMode ? translate('editPage') : translate('makePage')}
                                                    </LoadingButton>
                                                </Grid>
                                                </Hidden>
                                            </Grid>
                                    }
                                </AppCard>
                            </Grid>

                            <Grid item xs={12} md={4} lg={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Hidden smDown>
                                            <Grid item xs={12} style={{paddingTop: 0}}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    style={{width: "100%"}}
                                                    onClick={this.handleSubmit.bind(this)}
                                                    loading={SubmitLoading}
                                                >
                                                    {editMode ? translate('editPage'): translate('makePage')}
                                                </LoadingButton>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                    {
                                        !!editMode &&
                                        <Grid item xs={12}>
                                            <AppCard>
                                                {
                                                    this.state.loading ? <AppCardLoaderFields/> :
                                                        <>
                                                            <Button
                                                                component="a"
                                                                target="_blank"
                                                                size="small"
                                                                startIcon={<PageviewRoundedIcon/>}
                                                                href={makeSlug(slug)}
                                                                color="secondary"
                                                            >
                                                                {translate('viewPage')}
                                                            </Button>
                                                            <Typography className='date-holder'>
                                                                    <span>
                                                                        {translate('updated_at')}
                                                                    </span>
                                                                <span>
                                                                        {`${moment.tz(this.state.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                                    </span>
                                                            </Typography>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('created_at')}</span>
                                                                <span>
                                                                            {`${moment.tz(this.state.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                                    </span>
                                                            </Typography>

                                                        </>
                                                }
                                            </AppCard>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography color="textSecondary"
                                                                        style={{marginBottom: 0}}> </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SubTitle>{translate('pageCover')}</SubTitle>
                                                            <SingleImageChooser
                                                                primary
                                                                onChange={(thumbnail) => this.handleThumbImage(thumbnail)}
                                                                initial={thumbnail}
                                                            />
                                                            <Typography className={this.classes.condir} style={{textAlign:"center"}}>
                                                                {translate('pageCoverSize')}
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Hidden mdUp>
                                        <Grid item xs={12} style={{paddingTop: 0}}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                style={{width: "100%"}}
                                                onClick={this.handleSubmit.bind(this)}
                                                loading={SubmitLoading}
                                            >
                                                {editMode ? translate('editPage') : translate('makePage')}
                                            </LoadingButton>
                                        </Grid>
                                    </Hidden>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                    {
                        (slug==="help" && !this.state.loading) &&
                        <Grid item xs={12} lg={11}>
                            <AppCard
                                title={translate('help_items')}
                            >
                                <SlideShowsManager type={'help_item'} defined_width={512} defined_height={512}/>
                            </AppCard>
                        </Grid>
                    }
                </Grid>
                }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakePage))));
